// components/NotFound/NotFound.tsx

import React from "react";
import Layout from "../../components/MarketingPageLayout/MarketingPageLayout";
import { appName } from "../../utils/constants";
const NotFound: React.FC = () => {
  return (
    <Layout
      title={`Not found - ${appName}`}
      description={`Page not found - ${appName}`}
    >
      <div className="d-flex justify-content-center align-items-center vh-100 flex-column">
        <h1>404 - Not Found</h1>
        <p>The page you are looking for does not exist.</p>
      </div>
    </Layout>
  );
};

export default NotFound;
