/*
 * This is the navbar component. It will be a fixed component at the top of the screen. It will have a logo on the left side of the screen. It will have a link to the home page on the right side of the screen. It will have a link to the admin page on the right side of the screen. It will have a link to the new session page on the right side of the screen.
 */

import React from "react";
import { Link } from "react-router-dom";

import { appName } from "../../utils/constants";
import LogoLight from "../../images/logo-light.svg";
import LogoDark from "../../images/logo-dark.svg";
import { useTheme } from "../../contexts/ThemeContext";
const Navbar: React.FC = () => {
  const { isDarkMode } = useTheme();

  return (
    <div className="container">
      <nav className="navbar navbar-expand-lg" style={{ zIndex: 2 }}>
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img
              src={isDarkMode ? LogoLight : LogoDark}
              alt={`${appName} Logo`}
              className="d-inline-block img-thumbnail border-0 bg-transparent"
              loading="eager"
              width="130"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link" to="/about">
                  About
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="/pricing">
                  Pricing
                </Link>
              </li> */}
            </ul>
            {/* <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link" to="/new-session">
                  Play Now
                </Link>
              </li>
            </ul> */}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
