// slices/userSlice.ts
import { createSlice } from "@reduxjs/toolkit";

export interface UserStateBase {
  publicUUID: string | null;
  name: string | null;
  token: string | null;
}

export type UserState = UserStateBase & {
  [key: string]: string | number | boolean;
};

const userSlice = createSlice({
  name: "user",
  initialState: {
    publicUUID: null,
    name: null,
  } as UserState,
  reducers: {
    setPublicUUID: (state, action) => {
      state.publicUUID = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setName: (state, action) => {
      console.log("setName", action);
      state.name = action.payload;
    },
    setPublicMetadata: (state, action) => {
      console.log("setPublicMetadata", action);
      const { key, value } = action.payload;
      state[key] = value;
    },
    setIsAdmin: (state, action) => {
      console.log("setIsAdmin", action);
    },
  },
});

export const { actions: userActions, reducer: userReducer } = userSlice;
