import { GameSessionStatus } from "../../hooks/types";

import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { Button } from "react-bootstrap";
import { gameSessionActions } from "../../store/slices/gameSessionSlice";

type JoinButtonProps = {
  gameReady: boolean;
  size: "sm" | "lg";
};

const JoinButton: React.FC<JoinButtonProps> = ({ gameReady, size }) => {
  const { session_id: sessionId } = useParams();
  const { token } = useAuth();

  const { publicUUID } = useSelector((state: RootState) => state.user);
  const gameSession = useSelector((state: RootState) => state.gameSession);
  const { isAdmin } = useSelector((state: RootState) => state.session);

  const [canJoin, setCanJoin] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!gameSession || !publicUUID) return;

    if (gameSession.players.includes(publicUUID)) {
      setCanJoin(false);
    } else {
      setCanJoin(true);
    }
  }, [gameSession, publicUUID]);

  const handleJoinClick = () => {
    if (!gameSession || !sessionId || !token) return;

    dispatch(gameSessionActions.joinPlayerToGame({}));
  };

  if (!canJoin) return null;

  if (gameSession.status === GameSessionStatus.STARTED)
    return <p>Game Started</p>;

  if (gameSession.status !== GameSessionStatus.UNSTARTED) return null;

  let variant = "primary";
  if (isAdmin && gameReady) {
    variant = "info";
  }

  return (
    <Button onClick={handleJoinClick} variant={variant} size={size}>
      Join Game
    </Button>
  );
};

export default JoinButton;
