import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface NewGameButtonProps {
  showNewGameModal: () => void;
}

const NewGameButton: React.FC<NewGameButtonProps> = ({ showNewGameModal }) => {
  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement={"bottom"}
      overlay={
        <Popover id={`new-game-popover`} className="">
          <Popover.Body>New Game</Popover.Body>
        </Popover>
      }
    >
      <button
        className="btn btn-danger rounded-circle p-5 shadow-lg btn-lg text-white position-absolute top-50 start-50 translate-middle"
        onClick={showNewGameModal}
      >
        <FontAwesomeIcon icon={faPlus} size={"3x"} />
      </button>
    </OverlayTrigger>
  );
};

export default NewGameButton;
