import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";

const usePlayerBreakoutRoom = () => {
  // get the breakout rooms from the redux store state.gameSession.breakoutRoomPlan. This will either be
  // an empty array or an array of arrays of player IDs.

  const rooms = useSelector(
    (state: RootState) => state.gameSession.breakoutRoomPlan
  );
  const publicUUID = useSelector((state: RootState) => state.user.publicUUID);

  const [roomIndex, setRoomIndex] = useState<number | null>(null);

  useEffect(() => {
    if (rooms.length < 1 || !publicUUID) return;

    const playerRoom = rooms.find((room) => room.includes(publicUUID));

    if (playerRoom) {
      setRoomIndex(rooms.indexOf(playerRoom));
    } else {
      setRoomIndex(null);
    }
  }, [rooms, publicUUID]);

  return roomIndex;
};

export default usePlayerBreakoutRoom;
