/**
 * This will show the breakout room that the current player is in.
 */

import React, { useEffect, useState } from "react";

import { RootState } from "../../store";
import { useSelector } from "react-redux";
import Room from "./Room";
import usePlayerBreakoutRoom from "../../hooks/usePlayerBreakoutRoom";

function PlayerBreakoutRoom() {
  const roomIndex = usePlayerBreakoutRoom();
  const rooms = useSelector(
    (state: RootState) => state.gameSession.breakoutRoomPlan
  );

  if (roomIndex === null) return null;

  return (
    <Room roomName={`Breakout ${roomIndex + 1}`} players={rooms[roomIndex]} />
  );
}

export default PlayerBreakoutRoom;
