import React, { useState, useCallback, useMemo, useEffect } from "react";
import Layout from "../../components/MarketingPageLayout/MarketingPageLayout";
import { appName } from "../../utils/constants";
import NewGameSession from "../../components/NewGameSession/NewGameSession";

import useServerSentEvents from "../../hooks/useServerSentEvents";

import {
  GameSessionCallback,
  SessionAdminsCallback,
  GameSessionPlayersUpdateCallback,
  GameSessionStatus,
  BreakoutRoomStatus,
} from "../../hooks/types";

import GameSession from "../../components/GameSession/GameSession";

import SessionHeader from "../../components/SessionHeader/SessionHeader";
import AwaitingGamePlaceholder from "../../components/AwaitingGamePlaceholder/AwaitingGamePlaceholder";

import { useDispatch, useSelector } from "react-redux";
import { visitorsActions } from "../../store/slices/visitorsSlice";
import { RootState } from "../../store";
import { gameSessionActions } from "../../store/slices/gameSessionSlice";
import { sessionActions } from "../../store/slices/sessionSlice";
import { useParams } from "react-router-dom";
import { breakoutRoomActions, userActions } from "../../store/slices";
import { PostMessageTypes, PostMessageEvents } from "../../types";
import backgroundImage from "../../images/forest-background.svg";
import BreakoutRoomManagement from "../../components/BreakoutRoomManagement/BreakoutRoomManagement";
import useViewportHeight from "../../hooks/useViewportHeight";

const Session: React.FC = () => {
  const [showNewGameModal, setShowNewGameModal] = useState<boolean>(false);

  const visitors = useSelector((state: RootState) => state.visitors);

  const { session_id: sessionId } = useParams();

  const dispatch = useDispatch();

  // when the session first renders we should emit a "session-url-allocated" event as this will be listened for by
  // any client that hosts this in an iframe. This will allow the client to know the session url and then
  // connect others to the session.
  useEffect(() => {
    console.log("[collab] - SessionPage - useEffect - sessionId", sessionId);
    const targetOrigin = "*";
    const message = {
      messageType: PostMessageTypes.SESSION_ID_ALLOCATED,
      payload: sessionId,
    };
    try {
      window.parent.postMessage(message, targetOrigin);
    } catch (error) {
      console.error("Error posting message to parent window", error);
    }
  }, [sessionId]);

  // ResizeObserver logic
  // useEffect(() => {
  //   const resizeObserver = new ResizeObserver(() => {
  //     console.log("[collab] - SessionPage - [collab] - AppRouter - resizeObserver");
  //     const newHeight = document.body.scrollHeight;
  //     const targetOrigin = "*";
  //     const message = {
  //       messageType: PostMessageTypes.IFRAME_RESIZE,
  //       payload: newHeight,
  //     };

  //     window.parent.postMessage(message, targetOrigin);
  //   });

  //   resizeObserver.observe(document.body);

  //   // Cleanup
  //   return () => {
  //     console.log("[collab] - SessionPage - [collab] - AppRouter - resizeObserver - cleanup");
  //     resizeObserver.disconnect();
  //   };
  // }, []);

  useEffect(() => {
    dispatch(sessionActions.setSessionId(sessionId));
  }, [dispatch, sessionId]);

  // get a list of all the session admins
  const sessionAdminsSIDs = useMemo(() => {
    return Object.keys(visitors).filter((sid) => visitors[sid].isAdmin);
  }, [visitors]);

  const handleInit = useCallback(
    (data: { peers: string[] }) => {
      console.log("[collab] - SessionPage - handleInit", data);
      data.peers.forEach((sid) => {
        dispatch(visitorsActions.addVisitor({ sid }));
      });

      // send a message to the parent window to let it know that the session has been initialised with IS_SESSION_CONNECTED
      // set to true
      const targetOrigin = "*";
      const message = {
        messageType: PostMessageTypes.IS_SESSION_CONNECTED,
        payload: true,
      };
      try {
        window.parent.postMessage(message, targetOrigin);
      } catch (error) {
        console.error("Error posting message to parent window", error);
      }
    },
    [dispatch]
  );

  const handleSessionAdminUpdate: SessionAdminsCallback = useCallback(
    (session_admin_message_payload) => {
      console.log(
        "[collab] - SessionPage - handleSessionAdminUpdate",
        session_admin_message_payload
      );
      const { session_admins: sessionAdmins } = session_admin_message_payload;
      // check if any session admins have been removed and set their isAdmin flag to false
      sessionAdminsSIDs.forEach((sid) => {
        if (!sessionAdmins.includes(sid)) {
          dispatch(
            visitorsActions.setVisitorAdminStatus({ sid, isAdmin: false })
          );
        }
      });

      // set the isAdmin flag to true for all the session admins
      sessionAdmins.forEach((sid) => {
        dispatch(visitorsActions.setVisitorAdminStatus({ sid, isAdmin: true }));
      });
    },
    [dispatch, sessionAdminsSIDs]
  );

  const handleGameSessionUpdate: GameSessionCallback = useCallback(
    (game_session_payload) => {
      console.log(
        "[collab] - SessionPage - handleGameSessionUpdate",
        game_session_payload
      );
      dispatch(
        gameSessionActions.setGameSession(game_session_payload.game_session)
      );
    },
    [dispatch]
  );

  const handleGameSessionStatusUpdate = useCallback(
    (data: { status: GameSessionStatus }) => {
      console.log(
        "[collab] - SessionPage - handleGameSessionStatusUpdate",
        data
      );
      dispatch(gameSessionActions.setStatus(data.status));
    },
    [dispatch]
  );

  const handleGameSessionPlayersUpdate: GameSessionPlayersUpdateCallback =
    useCallback(
      (game_session_players) => {
        console.log(
          "[collab] - SessionPage - handleGameSessionPlayersUpdate",
          game_session_players
        );
        if (game_session_players.update_type === "add") {
          dispatch(gameSessionActions.addPlayer(game_session_players.sid));
        } else if (game_session_players.update_type === "remove") {
          dispatch(gameSessionActions.removePlayer(game_session_players.sid));
        }
      },
      [dispatch]
    );

  const handleBreakoutRoomPlanUpdate = useCallback(
    (data: { breakout_room_plan: string[][] }) => {
      console.log(
        "[collab] - SessionPage - handleBreakoutRoomPlanUpdate",
        data
      );
      dispatch(gameSessionActions.setBreakoutRoomPlan(data.breakout_room_plan));
    },
    [dispatch]
  );

  const handleConnected = useCallback(
    (data: { remoteSID: string }) => {
      console.log("[collab] - SessionPage - handleConnected", data);
      dispatch(visitorsActions.addVisitor({ sid: data.remoteSID }));
    },
    [dispatch]
  );

  const handleDisconnected = useCallback(
    (data: { remoteSID: string }) => {
      console.log("[collab] - SessionPage - handleDisconnected", data);
      // remove the remoteSID from the peerSids array
      dispatch(visitorsActions.removeVisitor({ sid: data.remoteSID }));
    },
    [dispatch]
  );

  const handlePlayerPublicDetailsUpdate = useCallback(
    // the data sent will definitely have a person_id field. However it can also have any
    // other fields that will have a key and value of strings. For example:
    // {'person_id': '263b9ad9-a743-4f52-9ebd-c8b345444e1e', 'name': 'John Doe'}
    // {'person_id': '263b9ad9-a743-4f52-9ebd-c8b345444e1e', 'name': 'John Doe', 'webex_id': 'john.doe@webex', 'avatar': 'https://avatar.com/1234'}
    (data: { person_id: string } & Record<string, string>) => {
      console.log(
        "[collab] - SessionPage - handlePlayerPublicDetailsUpdate",
        data
      );
      const { person_id: sid, ...metadata } = data;

      if (!sid) {
        console.error("No sid found for player public details update");
        return;
      }

      dispatch(
        visitorsActions.setVisitorMetadata({
          sid,
          metadata,
        })
      );
    },
    [dispatch]
  );

  // This will create a listener for messages from parent windows that will embed this in an iframe.
  // This will allow the parent window to send messages to this iframe.
  useEffect(() => {
    const handlePostMessage = (e: MessageEvent) => {
      // console.log("[collab] - SessionPage - handlePostMessage", e);
      const { data } = e;
      if (data.messageType === PostMessageEvents.UPDATE_USER_PUBLIC_METADATA) {
        console.log(
          "[collab] - SessionPage - handlePostMessage - UPDATE_USER_PUBLIC_METADATA",
          data
        );
        // [Log] 04:50:18.779handlePostMessage - UPDATE_USER_METADATA – {messageType: "update-user-public-metadata", payload: {person_id: "f160471e-ef6b-4a4f-9f0e-073344f5107c", webexUserId: "4bde949d-38b5-4d90-90d8-399692c1bec4"}} (bundle.js, line 7209)
        const { key, value } = data.payload;
        dispatch(
          userActions.setPublicMetadata({
            key,
            value,
          })
        );
      } else if (data.messageType === PostMessageEvents.UPDATE_IS_ADMIN) {
        console.log(
          "[collab] - SessionPage - handlePostMessage - UPDATE_IS_ADMIN",
          data
        );
        const { isAdmin } = data.payload;
        dispatch(userActions.setIsAdmin(isAdmin));
      } else if (
        data.messageType ===
        PostMessageEvents.UPDATE_BREAKOUT_ROOM_CREATION_MODE
      ) {
        console.log(
          "handlePostMessage - UPDATE BREAKOUT ROOM CREATION MODE",
          data
        );
        const { mode } = data.payload;
        dispatch(gameSessionActions.setBreakoutRoomCreationMode(mode));
      } else if (data.messageType === PostMessageEvents.OPEN_BREAKOUT_ROOMS) {
        console.log(
          "[collab] - SessionPage - handlePostMessage - OPEN BREAKOUT ROOMS",
          data
        );
        dispatch(
          gameSessionActions.serversideInitialiseBreakoutRoomPlan(data.payload)
        );
      } else if (data.messageType === PostMessageEvents.JOIN_PLAYER_TO_GAME) {
        console.log(
          "[collab] - SessionPage - handlePostMessage - JOIN PLAYER TO GAME",
          data
        );
        dispatch(gameSessionActions.joinPlayerToGame({}));
      } else if (
        data.messageType === PostMessageEvents.JOIN_PLAYER_TO_BREAKOUT_ROOM
      ) {
        console.log(
          "[collab] - SessionPage - handlePostMessage - JOIN_PLAYER_TO_BREAKOUT_ROOM",
          data
        );
        const { breakoutRoomIndex } = data.payload;
        dispatch(
          gameSessionActions.joinPlayerToBreakoutRoomPlan({ breakoutRoomIndex })
        );
      } else {
        console.debug(
          "[collab] - SessionPage - handlePostMessage - message not recognised",
          e
        );
      }
    };

    window.addEventListener("message", handlePostMessage);

    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, [handlePlayerPublicDetailsUpdate, dispatch]);

  const viewportHeight = useViewportHeight();

  // breakout room details will have the shape: {'game_id': None, 'game_session_id': None, 'room_name': None,
  // 'room_description': None, 'players': ['263b9ad9-a743-4f52-9ebd-c8b345444e1e',
  // '87ac65d9-2dd6-4cd7-8467-792e709fdede'], 'url': 'https://create-game-url.com/some-uuid'}

  const handleBreakoutRoomDetails = useCallback(
    (data: {
      game_id: string | null;
      game_session_id: string | null;
      room_name: string | null;
      room_description: string | null;
      players: string[];
      url: string;
      id: string;
      status: BreakoutRoomStatus;
    }) => {
      console.log("[collab] - SessionPage - handleBreakoutRoomDetails", data);
      dispatch(
        breakoutRoomActions.setBreakoutRoom({
          peers: data.players,
          gameUrl: data.url,
          status: data.status,
          id: data.id,
        })
      );
    },
    [dispatch]
  );

  const callbacks = {
    init: handleInit,
    connected: handleConnected,
    disconnected: handleDisconnected,
    session_admins: handleSessionAdminUpdate,
    active_game: handleGameSessionUpdate,
    game_session_status_update: handleGameSessionStatusUpdate,
    game_session_players_update: handleGameSessionPlayersUpdate,
    game_session_breakout_room_plan_update: handleBreakoutRoomPlanUpdate,
    player_public_details_update: handlePlayerPublicDetailsUpdate,
    breakout_room_details: handleBreakoutRoomDetails,
  };

  useServerSentEvents(callbacks);

  const [showBreakoutRoomManagement, setShowBreakoutRoomManagement] =
    useState(false);

  return (
    <Layout
      showFooter={false}
      showNavbar={false}
      title={`${appName} - My Session`}
      description={`${appName} session`}
    >
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "100%",
          minHeight: `${viewportHeight}px`,
        }}
      >
        <SessionHeader />

        <div className="row g-0">
          <div className="col">
            <AwaitingGamePlaceholder
              showNewGameModal={() => setShowNewGameModal(true)}
            />

            <GameSession onNewGame={() => setShowNewGameModal(true)} />
          </div>
        </div>

        <NewGameSession
          show={showNewGameModal}
          onClose={() => setShowNewGameModal(false)}
        />
      </div>

      {/* {showNewGameModal && ( */}
      <BreakoutRoomManagement showNewGameModal={showNewGameModal} />
      {/* )} */}
    </Layout>
  );
};

export default Session;
