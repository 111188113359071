import CustomLink from "../../components/CustomLink/CustomLink";
import Layout from "../../components/MarketingPageLayout/MarketingPageLayout";
import { appName, companyDetails } from "../../utils/constants";

const PrivacyPage = () => (
  <Layout title={`Privacy Policy`} description={`Privacy Policy`}>
    <div className="row">
      <div className="col card body-text-card col-10 offset-1">
        <div className="card-body">
          <div className="row">
            <div className="col mb-5">
              <h1>Privacy Policy </h1>
            </div>
          </div>
          <div className="row">
            <div className="col lead"></div>
          </div>
          <p>
            This privacy policy applies between you, the User of this Website
            and {companyDetails.product_name}, the owner and provider of this
            Website. {companyDetails.product_name} takes the privacy of your
            information very seriously. This privacy policy applies to our use
            of any and all Data collected by us or provided by you in relation
            to your use of the Website.
          </p>
          <p>
            This privacy policy should be read alongside, and in addition to,
            our Terms and Conditions, which can be found at:{" "}
            <CustomLink to="/legal/terms" />.
          </p>
          <p>
            <b>Please read this privacy policy carefully</b>.
          </p>
          <h2>Definitions and interpretation</h2>
          <ol>
            <li>
              In this privacy policy, the following definitions are used:
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      <b>Data</b>
                    </td>
                    <td>
                      collectively all information that you submit to{" "}
                      {companyDetails.product_name} via the Website. This
                      definition incorporates, where applicable, the definitions
                      provided in the Data Protection Laws;
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Cookies</b>
                    </td>
                    <td>
                      a small text file placed on your computer by this Website
                      when you visit certain parts of the Website and/or when
                      you use certain features of the Website. Details of the
                      cookies used by this Website are set out in the clause
                      below (<b>Cookies</b>
                      );
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Data Protection Laws</b>
                    </td>
                    <td>
                      any applicable law relating to the processing of personal
                      Data, including but not limited to the Directive 96/46/EC
                      (Data Protection Directive) or the GDPR, and any national
                      implementing laws, regulations and secondary legislation,
                      for as long as the GDPR is effective in the UK;
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>GDPR</b>
                    </td>
                    <td>
                      the General Data Protection Regulation (EU) 2016/679;
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>
                        {companyDetails.name},
                        <br />
                        we
                      </b>{" "}
                      or <b>us</b>
                    </td>
                    <td>
                      {companyDetails.name}, a company incorporated in{" "}
                      {companyDetails.registered_with} with registered number{" "}
                      {companyDetails.number} whose registered office is at{" "}
                      {companyDetails.address_full};
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>{companyDetails.product_name}</b>
                    </td>
                    <td>a trading name of {companyDetails.name};</td>
                  </tr>
                  <tr>
                    <td>
                      <b>UK and EU Cookie Law</b>
                    </td>
                    <td>
                      the Privacy and Electronic Communications (EC Directive)
                      Regulations 2003 as amended by the Privacy and Electronic
                      Communications (EC Directive) (Amendment) Regulations
                      2011;
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>User</b> or <b>you</b>{" "}
                    </td>
                    <td>
                      any third party that accesses the Website and is not
                      either (i) employed by {companyDetails.product_name} and
                      acting in the course of their employment or (ii) engaged
                      as a consultant or otherwise providing services to
                      {companyDetails.product_name} and accessing the Website in
                      connection with the provision of such services; and
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Website</b>
                    </td>
                    <td>
                      the website that you are currently using,{" "}
                      <CustomLink to="" /> , and any sub-domains of this site
                      unless expressly excluded by their own terms and
                      conditions.
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              In this privacy policy, unless the context requires a different
              interpretation:
              <ol>
                <li>the singular includes the plural and vice versa;</li>
                <li>
                  references to sub-clauses, clauses, schedules or appendices
                  are to sub-clauses, clauses, schedules or appendices of this
                  privacy policy;
                </li>
                <li>
                  a reference to a person includes firms, companies, government
                  entities, trusts and partnerships;
                </li>
                <li>
                  "including" is understood to mean "including without
                  limitation";
                </li>
                <li>
                  reference to any statutory provision includes any modification
                  or amendment of it;
                </li>
                <li>
                  the headings and sub-headings do not form part of this privacy
                  policy.
                </li>
              </ol>
            </li>
          </ol>
          <h2>Scope of this privacy policy</h2>
          <ol>
            <li>
              This privacy policy applies only to the actions of{" "}
              {companyDetails.product_name} and Users with respect to this
              Website. It does not extend to any websites that can be accessed
              from this Website including, but not limited to, any links we may
              provide to social media websites.
            </li>
            <li>
              For purposes of the applicable Data Protection Laws,{" "}
              {companyDetails.product_name} is the "data controller". This means
              that {companyDetails.product_name} determines the purposes for
              which, and the manner in which, your Data is processed.
            </li>
          </ol>
          <h2>Data collected</h2>
          <ol>
            <li>
              We may collect the following Data, which includes personal Data,
              from you:
              <ol>
                <li>name;</li>
                <li>
                  contact Information such as email addresses and telephone
                  numbers;
                </li>
                <li>IP address (automatically collected);</li>
                <li>web browser type and version (automatically collected);</li>
                <li>operating system (automatically collected);</li>
                <li>
                  a list of URLs starting with a referring site, your activity
                  on this Website, and the site you exit to (automatically
                  collected);
                </li>
                in each case, in accordance with this privacy policy.
              </ol>
            </li>
          </ol>
          <h2>How we collect Data</h2>
          <ol>
            <li>
              We collect Data in the following ways:
              <ol>
                <li>data is given to us by you;</li>
                <li>data is received from other sources; and</li>
                <li>data is collected automatically.</li>
              </ol>
            </li>
          </ol>
          <h2>Data that is given to us by you</h2>
          <ol>
            <li>
              will collect your Data in a number of ways, for example:
              <ol>
                <li>
                  when you contact us through the Website, by telephone, post,
                  e-mail or through any other means;
                </li>
                <li>
                  when you register with us and set up an account to receive our
                  products/services;
                </li>
                <li>when you use our services;</li>
                <p>in each case, in accordance with this privacy policy.</p>
              </ol>
            </li>
          </ol>
          <h2>Data that is received from third parties</h2>
          <ol>
            <li>
              will receive Data about you from the following third parties:
              <ol></ol>
            </li>
          </ol>
          <h2>
            Data that is received from publicly available third parties sources
          </h2>
          <ol>
            <li>
              We will receive Data about you from the following publicly
              available third party sources:
              <ol></ol>
            </li>
          </ol>
          <h2>Data that is collected automatically</h2>
          <ol>
            <li>
              To the extent that you access the Website, we will collect your
              Data automatically, for example:
              <ol>
                <li>
                  we automatically collect some information about your visit to
                  the Website. This information helps us to make improvements to
                  Website content and navigation, and includes your IP address,
                  the date, times and frequency with which you access the
                  Website and the way you use and interact with its content.
                </li>
                <li>
                  we will collect your Data automatically via cookies, in line
                  with the cookie settings on your browser. For more information
                  about cookies, and how we use them on the Website, see the
                  section below, headed "Cookies".
                </li>
              </ol>
            </li>
          </ol>
          <h2>Our use of Data</h2>
          <ol>
            <li>
              Any or all of the above Data may be required by us from time to
              time in order to provide you with the best possible service and
              experience when using our Website. Specifically, Data may be used
              by us for the following reasons:
              <ol>
                <li>internal record keeping;</li>
                <li>improvement of our products / services;</li>
                <li>to facilitate the operation of our service;</li>
              </ol>
            </li>
            <p>in each case, in accordance with this privacy policy.</p>
            <li>
              We may use your Data for the above purposes if we deem it
              necessary to do so for our legitimate interests. If you are not
              satisfied with this, you have the right to object in certain
              circumstances (see the section headed "Your rights" below).
            </li>
            <li>
              When you register with us and set up an account to receive our
              services, the legal basis for this processing is the performance
              of a contract between you and us and/or taking steps, at your
              request, to enter into such a contract.
            </li>
          </ol>
          <h2>Who we share Data with</h2>
          <ol>
            <li>
              We may share your Data with the following groups of people for the
              following reasons:
              <ol>
                <li>
                  any of our group companies or affiliates - to ensure the
                  proper administration of our website and business;
                </li>
                <li>
                  our employees, agents and/or professional advisors - to help
                  facilitate the operation of your account and of our service;
                </li>
                <li>
                  third party service providers who provide services to us which
                  require the processing of personal data - to help third party
                  service providers in receipt of any shared data to perform
                  functions on our behalf to help ensure the website runs
                  smoothly;
                </li>
                <li>
                  relevant authorities - to facilitate the detection of crime or
                  the collection of taxes or duties;
                </li>
                <p>in each case, in accordance with this privacy policy.</p>
              </ol>
            </li>
          </ol>
          <h2>Keeping Data secure</h2>
          <ol>
            <li>
              We will use technical and organisational measures to safeguard
              your Data, for example:
              <ol>
                <li>
                  access to your account is controlled by a password and a user
                  name that is unique to you.
                </li>
                <li>we store your Data on secure servers.</li>
              </ol>
            </li>
            <li>
              Technical and organisational measures include measures to deal
              with any suspected data breach. If you suspect any misuse or loss
              or unauthorised access to your Data, please let us know
              immediately by contacting us via this e-mail address:{" "}
              {companyDetails.help_email}.
            </li>
            <li>
              If you want detailed information from Get Safe Online on how to
              protect your information and your computers and devices against
              fraud, identity theft, viruses and many other online problems,
              please visit www.getsafeonline.org. Get Safe Online is supported
              by HM Government and leading businesses.
            </li>
          </ol>
          <h2>Data retention</h2>
          <ol>
            <li>
              Unless a longer retention period is required or permitted by law,
              we will only hold your Data on our systems for the period
              necessary to fulfil the purposes outlined in this privacy policy
              or until you request that the Data be deleted.
            </li>
            <li>
              Even if we delete your Data, it may persist on backup or archival
              media for legal, tax or regulatory purposes.
            </li>
          </ol>
          <h2>Your rights</h2>
          <ol>
            <li>
              You have the following rights in relation to your Data:
              <ol>
                <li>
                  <b>Right to access</b>- the right to request (i) copies of the
                  information we hold about you at any time, or (ii) that we
                  modify, update or delete such information. If we provide you
                  with access to the information we hold about you, we will not
                  charge you for this, unless your request is "manifestly
                  unfounded or excessive." Where we are legally permitted to do
                  so, we may refuse your request. If we refuse your request, we
                  will tell you the reasons why.
                </li>
                <li>
                  <b>Right to correct</b>- the right to have your Data rectified
                  if it is inaccurate or incomplete.
                </li>
                <li>
                  <b>Right to erase</b>- the right to request that we delete or
                  remove your Data from our systems.
                </li>
                <li>
                  <b>Right to restrict our use of your Data</b>- the right to
                  "block" us from using your Data or limit the way in which we
                  can use it.
                </li>
                <li>
                  <b>Right to data portability</b>- the right to request that we
                  move, copy or transfer your Data.
                </li>
                <li>
                  <b>Right to object</b>- the right to object to our use of your
                  Data including where we use it for our legitimate interests.
                </li>
              </ol>
            </li>
            <li>
              To make enquiries, exercise any of your rights set out above, or
              withdraw your consent to the processing of your Data (where
              consent is our legal basis for processing your Data), please
              contact us via this e-mail address: {companyDetails.help_email}.
            </li>
            <li>
              If you are not satisfied with the way a complaint you make in
              relation to your Data is handled by us, you may be able to refer
              your complaint to the relevant data protection authority. For the
              UK, this is the Information Commissioner's Office (ICO). The ICO's
              contact details can be found on their website at{" "}
              https://ico.org.uk/.
            </li>
            <li>
              It is important that the Data we hold about you is accurate and
              current. Please keep us informed if your Data changes during the
              period for which we hold it.
            </li>
          </ol>
          <h2>Transfers outside the European Economic Area</h2>
          <ol>
            <li>
              Data which we collect from you may be stored and processed in and
              transferred to countries outside of the European Economic Area
              (EEA). For example, this could occur if our servers are located in
              a country outside the EEA or one of our service providers is
              situated in a country outside the EEA. We also share information
              with our group companies, some of which are located outside the
              EEA.
            </li>
            <li>
              We will only transfer Data outside the EEA where it is compliant
              with data protection legislation and the means of transfer
              provides adequate safeguards in relation to your data, eg by way
              of data transfer agreement, incorporating the current standard
              contractual clauses adopted by the European Commission, or by
              signing up to the EU-US Privacy Shield Framework, in the event
              that the organisation in receipt of the Data is based in the
              United States of America.
            </li>
            <li>
              To ensure that your Data receives an adequate level of protection,
              we have put in place appropriate safeguards and procedures with
              the third parties we share your Data with. This ensures your Data
              is treated by those third parties in a way that is consistent with
              the Data Protection Laws.
            </li>
          </ol>
          <h2>Links to other websites</h2>
          <ol>
            <li>
              This Website may, from time to time, provide links to other
              websites. We have no control over such websites and are not
              responsible for the content of these websites. This privacy policy
              does not extend to your use of such websites. You are advised to
              read the privacy policy or statement of other websites prior to
              using them.
            </li>
          </ol>
          <h2>Changes of business ownership and control</h2>
          <ol>
            <li>
              {companyDetails.product_name} may, from time to time, expand or
              reduce our business and this may involve the sale and/or the
              transfer of control of all or part of{" "}
              {companyDetails.product_name}. Data provided by Users will, where
              it is relevant to any part of our business so transferred, be
              transferred along with that part and the new owner or newly
              controlling party will, under the terms of this privacy policy, be
              permitted to use the Data for the purposes for which it was
              originally supplied to us.
            </li>
            <li>
              We may also disclose Data to a prospective purchaser of our
              business or any part of it.
            </li>
            <li>
              In the above instances, we will take steps with the aim of
              ensuring your privacy is protected.
            </li>
          </ol>
          <h2>Cookies</h2>
          <ol>
            <li>
              This Website may place and access certain Cookies on your
              computer. {companyDetails.product_name} uses Cookies to improve
              your experience of using the Website and to improve our range of
              services. {companyDetails.product_name} has carefully chosen these
              Cookies and has taken steps to ensure that your privacy is
              protected and respected at all times.
            </li>
            <li>
              All Cookies used by this Website are used in accordance with
              current UK and EU Cookie Law.
            </li>
            <li>
              Before the Website places Cookies on your computer, you will be
              presented with a message bar requesting your consent to set those
              Cookies. By giving your consent to the placing of Cookies, you are
              enabling {companyDetails.product_name} to provide a better
              experience and service to you. You may, if you wish, deny consent
              to the placing of Cookies; however certain features of the Website
              may not function fully or as intended.
            </li>
            <li>This Website may place the following Cookies:</li>
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    <b>Type of Cookie</b>
                  </td>
                  <td>
                    <b>Purpose</b>
                  </td>
                </tr>
                <tr>
                  <td>Strictly necessary cookies</td>
                  <td>
                    These are cookies that are required for the operation of our
                    website. They include, for example, cookies that enable you
                    to log into secure areas of our website, use a shopping cart
                    or make use of e-billing services.
                  </td>
                </tr>
                <tr>
                  <td>Analytical/performance cookies</td>
                  <td>
                    They allow us to recognise and count the number of visitors
                    and to see how visitors move around our website when they
                    are using it. This helps us to improve the way our website
                    works, for example, by ensuring that users are finding what
                    they are looking for easily.
                  </td>
                </tr>
                <tr>
                  <td>Functionality cookies</td>
                  <td>
                    These are used to recognise you when you return to our
                    website. This enables us to personalise our content for you,
                    greet you by name and remember your preferences (for
                    example, your choice of language or region).
                  </td>
                </tr>
              </tbody>
            </table>
            <li>
              You can find a list of Cookies that we use in the Cookies
              Schedule.
            </li>
            <li>
              You can choose to enable or disable Cookies in your internet
              browser. By default, most internet browsers accept Cookies but
              this can be changed. For further details, please consult the help
              menu in your internet browser.
            </li>
            <li>
              You can choose to delete Cookies at any time; however you may lose
              any information that enables you to access the Website more
              quickly and efficiently including, but not limited to,
              personalisation settings.
            </li>
            <li>
              It is recommended that you ensure that your internet browser is
              up-to-date and that you consult the help and guidance provided by
              the developer of your internet browser if you are unsure about
              adjusting your privacy settings.
            </li>
            <li>
              For more information generally on cookies, including how to
              disable them, please refer to aboutcookies.org. You will also find
              details on how to delete cookies from your computer.
            </li>
          </ol>
          <h2>General</h2>
          <ol>
            <li>
              You may not transfer any of your rights under this privacy policy
              to any other person. We may transfer our rights under this privacy
              policy where we reasonably believe your rights will not be
              affected.
            </li>
            <li>
              If any court or competent authority finds that any provision of
              this privacy policy (or part of any provision) is invalid, illegal
              or unenforceable, that provision or part-provision will, to the
              extent required, be deemed to be deleted, and the validity and
              enforceability of the other provisions of this privacy policy will
              not be affected.
            </li>
            <li>
              Unless otherwise agreed, no delay, act or omission by a party in
              exercising any right or remedy will be deemed a waiver of that, or
              any other, right or remedy.
            </li>
            <li>
              This Agreement will be governed by and interpreted according to
              the law of England and Wales. All disputes arising under the
              Agreement will be subject to the exclusive jurisdiction of the
              English and Welsh courts.
            </li>
          </ol>
          <h2>Changes to this privacy policy</h2>
          <ol>
            <li>
              {companyDetails.product_name} reserves the right to change this
              privacy policy as we may deem necessary from time to time or as
              may be required by law. Any changes will be immediately posted on
              the Website and you are deemed to have accepted the terms of the
              privacy policy on your first use of the Website following the
              alterations.
              <br />
              <br />
              You may contact {companyDetails.product_name} by email at{" "}
              {companyDetails.help_email}.
            </li>
          </ol>
          <h2>Attribution</h2>
          <ol>
            <li>
              This privacy policy was created using a document from{" "}
              <a href="https://www.rocketlawyer.co.uk/">Rocket Lawyer</a>
              (https://www.rocketlawyer.co.uk).
            </li>
            <p>
              <b>11 November 2023</b>
            </p>
          </ol>
          <p></p>
          <h2>Cookies</h2>
          <p>
            Below is a list of the cookies that we use. We have tried to ensure
            this is complete and up to date, but if you think that we have
            missed a cookie or there is any discrepancy, please let us know.
          </p>
          <p>Strictly necessary</p>
          <p>We use the following strictly necessary cookies:</p>
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <b>Description of Cookie</b>
                </td>
                <td>
                  <b>Purpose</b>
                </td>
              </tr>
              <tr>
                <td>Session cookie</td>
                <td>
                  We use this session cookie to remember you and maintain your
                  session whilst you are using our website.
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <br />
          <p>Analytical/performance</p>
          <p>We use the following analytical/performance cookies:</p>
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <b>Description of Cookie</b>
                </td>
                <td>
                  <b>Purpose</b>
                </td>
              </tr>
              <tr>
                <td>Analytics cookie</td>
                <td>
                  We use this cookie to help us analyse how users use the
                  website.
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <br />
          <p>Functionality</p>
          <p>We use the following functionality cookies:</p>
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <b>Description of Cookie</b>
                </td>
                <td>
                  <b>Purpose</b>
                </td>
              </tr>
              <tr>
                <td>Functionality cookie</td>
                <td>
                  We use this cookie to identify your computer and analyse
                  traffic patterns on our website.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
);

export default PrivacyPage;
