import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";

import "./scss/app.scss";
import AppRouter from "./AppRouter";
import reportWebVitals from "./reportWebVitals";
import store from "./store";

import themeUtils from "./utils/themeUtils";
import { ThemeProvider } from "./contexts/ThemeContext";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import { checkAndRefreshVisitorToken } from "./store/api/auth";
import { PostMessageTypes } from "./types";
import { getVerticalScrollbarWidth } from "./utils";

const queryClient = new QueryClient();

const getHorizontalScrollbarWidth = () => {
  const beforeScrollbarHidden = document.body.clientWidth;
  const overflowState = document.body?.style.overflow;

  document.body.style.overflow = "hidden"; // hide the scrollbar
  const afterScrollbarHidden = document.body.clientWidth;
  document.body.style.overflow = overflowState; // restore the overflow state

  return beforeScrollbarHidden - afterScrollbarHidden;
};

const App: React.FC = () => {
  const { authenticateVisitor } = useAuth();

  useEffect(() => {
    (async () => {
      await checkAndRefreshVisitorToken(authenticateVisitor);
    })();
  }, [authenticateVisitor]);

  useEffect(() => {
    let debounce: NodeJS.Timeout | number; // Moved inside useEffect
    let lastSentHeight = 0; // Track the last sent height

    const resizeObserver = new ResizeObserver(() => {
      // get hight of div with root id
      const newRootHeight = document.getElementById("root")?.clientHeight;
      console.log("[collab] - AppRouter - newRootHeight", newRootHeight);

      console.log("[collab] - AppRouter - resizeObserver fired");
      // Debounce sending of height updates
      clearTimeout(debounce);
      debounce = setTimeout(() => {
        let newHeight = document.body.scrollHeight;
        newHeight += Math.abs(getVerticalScrollbarWidth());
        console.log("[collab] - AppRouter - sending new height", newHeight);

        if (newHeight === lastSentHeight) {
          console.log(
            "[collab] - AppRouter - newHeight === lastSentHeight - returning"
          );
          return;
        }

        const message = {
          messageType: PostMessageTypes.IFRAME_RESIZE,
          payload: newHeight,
        };

        window.parent.postMessage(message, "*");
      }, 100);
    });

    resizeObserver.observe(document.body);

    return () => {
      console.log("[collab] - AppRouter - cleaning up");
      clearTimeout(debounce);
      resizeObserver.disconnect();
    };
  }, []);

  // Initialize theme utils - done as a hook
  // themeUtils();

  return <AppRouter />;
};

const Root: React.FC = () => {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <AuthProvider>
            <App />
          </AuthProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </Provider>
  );
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <Root />
);

reportWebVitals();
