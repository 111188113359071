/*
 * This is the Select Breakout Group Size component. It will present the user with 2 options:
 * 1. 1 minute
 * 2. 10 minutes
 *
 * The two options will fly in from the right one at a time. They will appear as buttons in the center of the screen.
 * When the user selects an option, the option will fly off the screen to the left and will callback to the parent
 * with the selected option.
 */

import React, { useState, useEffect, useCallback } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faPeopleRoof } from "@fortawesome/free-solid-svg-icons";

import { ENDPOINTS, GameActionEnum } from "../../store/api/urls";
import { ReqHeaders } from "../../store/api/auth";
import { useQuery } from "react-query";
import LoadingMessage from "../LoadingMessage/LoadingMessage";

const fetchGroupSizeOptions = async (gameLength: number) => {
  const url = ENDPOINTS.games({ action: GameActionEnum.FILTER_OPTIONS });
  let headers: ReqHeaders = {
    "Content-Type": "application/json",
  };

  const queryParams = new URLSearchParams({
    filter: "group_size",
    time_requirement: gameLength.toString(), // required to get the correct group size options
  });

  const response = await fetch(url + "?" + queryParams, {
    headers,
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

interface SelectBreakoutRoomSizeProps {
  onSelect: (groupSize: string) => void;
  selectedOption?: string;
  gameLength: number;
}

const SelectBreakoutRoomSize: React.FC<SelectBreakoutRoomSizeProps> = ({
  onSelect,
  selectedOption,
  gameLength,
}) => {
  const [groupSizeOptions, setGroupSizeOptions] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  // this will use react-query to get the game length options from the server using the ENDPOINTS.games filter options action
  // where the filter is the time_requirement. The API will respond with a list of time requirements that the user can select
  // from e.g. [{"name":"1 minutes","value":1},{"name":"10 minutes","value":10}]
  const { isLoading, error, data } = useQuery(
    [`groupSizeOptions-${gameLength}`, { filter: "time_requirement" }],
    () => fetchGroupSizeOptions(gameLength),
    {
      retry: false, // Do not retry on failure.
      refetchOnWindowFocus: false, // To not refetch on window focus.
    }
  );

  const handleSelect = useCallback(
    (option: string) => {
      setShowSpinner(true);
      setTimeout(() => {
        setShowSpinner(false);
        onSelect(option);
      }, 1000);
    },
    [onSelect]
  );

  useEffect(() => {
    if (data) {
      setGroupSizeOptions(data);
    }

    // if there's only one option, select it with handleSelect after a short delay
    if (data && data.length === 1) {
      setTimeout(() => {
        handleSelect(data[0].value);
      }, 1000);
    }
  }, [data, handleSelect]);

  if (isLoading) return <LoadingMessage message={"Loading options"} />;

  if (error) return <div>Error fetching options...</div>;

  return (
    <div className="text-center">
      <h2 className="mb-4">
        <FontAwesomeIcon icon={faPeopleRoof} />
        <br className="mb-2" />
        Breakout Room Size
      </h2>

      {!showSpinner &&
        groupSizeOptions.map((option: any) => (
          <button
            key={option.value}
            className={`btn ms-1 me-1 ${
              selectedOption === option.value
                ? "btn-danger"
                : "btn-outline-success"
            }`}
            onClick={() => handleSelect(option.value)}
          >
            {option.name}
          </button>
        ))}

      {showSpinner && (
        <>
          <br />
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </>
      )}

      {!showSpinner && groupSizeOptions.length === 1 && (
        <p className="mt-3">
          <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
          More options coming soon!
        </p>
      )}
    </div>
  );
};

export default SelectBreakoutRoomSize;
