/*
This page will describe how to make use of the Chinwag Collab Application in a Webex Meeting.
*/

import Row from "react-bootstrap/Row";
import Layout from "../../components/MarketingPageLayout/MarketingPageLayout";
import { appName } from "../../utils/constants";
import Col from "react-bootstrap/Col";
import Step1 from "../../images/WebexPage/step-1.jpeg";
import Step2 from "../../images/WebexPage/step-2.jpeg";
import Step3 from "../../images/WebexPage/step-3.webp";
import Step4 from "../../images/WebexPage/step-4.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import styles from "./styles.module.scss";

const WebexPage: React.FC = () => {
  return (
    <Layout
      title={`How to use ${appName} in a Webex Meeting`}
      description={`How to use ${appName} in a Webex Meeting`}
    >
      <div className="row">
        <div className="col col-lg-8 offset-lg-2 card body-text-card">
          <div className="card-body">
            <Row className="pb-5">
              <Col className="text-center">
                <h1>
                  {appName}
                  <span
                    style={{
                      color: "#dc3545",
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon
                      icon={faHeart}
                      className={`${styles["spin"]}`}
                    />{" "}
                  </span>
                  &apos;s Webex meetings
                </h1>
              </Col>
            </Row>
            <Row className="align-items-center justify-content-between pb-5 ">
              <Col md={4}>
                <h2>
                  <span className="rainbow">
                    Grab {appName} from Webex Apps
                  </span>
                </h2>
                <ol>
                  <li>Start your meeting (up to 400 people)</li>
                  <li>Open the Apps menu (picture alongside)</li>
                  <li>Search for {appName} </li>
                  <li>Click on the {appName} app to start </li>
                </ol>
              </Col>
              <Col>
                <img
                  src={Step1}
                  alt="Step 1"
                  className="img-fluid img-thumbnail"
                />
              </Col>
            </Row>
            <Row className="align-items-center justify-content-between pb-5">
              <Col className="order-md-1" md={4}>
                <h2 className="rainbow">Open for all</h2>
                <p>Click the {appName} "Open for all"button when prompted</p>
              </Col>
              <Col className="order-md-0">
                <img
                  src={Step2}
                  alt="Step 2"
                  className="img-fluid img-thumbnail"
                />
              </Col>
            </Row>
            <Row className="align-items-center justify-content-between pb-5">
              <Col md={4}>
                <h2 className="rainbow">
                  <span>Open external link</span> 🔗
                </h2>
                <p>Click the "Open external link" button when prompted</p>
              </Col>
              <Col>
                <img
                  src={Step3}
                  alt="Step 3"
                  className="img-fluid img-thumbnail"
                />
              </Col>
            </Row>
            <Row className="align-items-center justify-content-between">
              <Col className="order-md-1" md={4}>
                <h2>
                  <span className="rainbow">Push the button</span> 🎉
                </h2>
                <p>
                  Push the big (pinkish?) button to setup your {appName} game
                </p>
              </Col>
              <Col className="order-md-0">
                <img
                  src={Step4}
                  alt="Step 4"
                  className="img-fluid img-thumbnail"
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WebexPage;
