// SuccessMessage.tsx

import React from "react";

import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
interface Props {
  message: string;
}

const SuccessMessage: React.FC<Props> = ({ message }) => {
  return (
    // make sure this div flows down the page so that the loading message is always at the bottom
    <div className="d-flex align-items-center flex-column">
      {/* fontawesome checkmark */}
      <div className="text-center mb-5 text-success">
        <FontAwesomeIcon icon={faCheckCircle} size="5x" />
      </div>
      <div className="text-center">
        <p className="lead">{message}</p>
      </div>
    </div>
  );
};

export default SuccessMessage;
