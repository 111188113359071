import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";
import cloud from "./cloud.png";

const CLOUD_COUNT = 5;

function CloudsComponent() {
  // Define state for the initial positions of the clouds
  const [cloudPositions, setCloudPositions] = useState<
    { x: number; y: number; id: string }[]
  >([]);
  const [cloudHeight, setCloudHeight] = useState<number>(100);

  useEffect(() => {
    const newClouds: { x: number; y: number; id: string }[] = [];
    Array(CLOUD_COUNT)
      .fill(null)
      .forEach((_c, index) => {
        // random x position can be -50% of the window width to 50% of the window width
        const getRandomXPosition = () => {
          const fiftyPercentLeft = (Math.random() * -1 * window.innerWidth) / 2;
          const fiftyPercentRight = (Math.random() * window.innerWidth) / 2;
          return Math.floor(fiftyPercentLeft + fiftyPercentRight);
        };

        const getRandomYPosition = () =>
          Math.floor(Math.random() * window.innerHeight);

        // Set the initial position of the clouds
        const newCloud = {
          x: getRandomXPosition(),
          y: getRandomYPosition(),
          id: `cloud-${index}`,
        };

        newClouds.push(newCloud);
      });
    setCloudPositions(newClouds);
  }, []);

  // resize the clouds when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setCloudHeight(Math.floor(window.innerHeight / 10));
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      {cloudPositions.map((c) => (
        <div
          key={c.id}
          className={styles.cloud}
          style={{
            top: c.y,
            left: c.x,
            backgroundImage: `url(${cloud})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            height: `${cloudHeight}px`,
            width: `${cloudHeight}px`,
            transform: `scale(${0.3 + Math.random()})`,
            animationDuration: `${100 + 10 * CLOUD_COUNT * Math.random()}s`,
            animationDelay: `${-100 * CLOUD_COUNT * Math.random()}s`,
          }}
        />
      ))}
    </div>
  );
}

export default CloudsComponent;
