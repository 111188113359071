// PlayerList.tsx
import React, { useMemo } from "react";

import styles from "./styles.module.scss";
import { useAuth } from "../../contexts/AuthContext";
import { VisitorType } from "../../store/slices/visitorsSlice";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdBadge, faPerson } from "@fortawesome/free-solid-svg-icons";

type PlayerListProps = {};

const UserIcon = ({ isAdmin }: { isAdmin: boolean }) => {
  if (isAdmin) {
    return <FontAwesomeIcon icon={faIdBadge} listItem />;
  }
  return <FontAwesomeIcon icon={faPerson} listItem />;
};

const Visitor: React.FC<{
  visitor: VisitorType;
  isCurrentPlayer: boolean;
}> = ({ visitor, isCurrentPlayer }) => {
  const textClass = isCurrentPlayer ? "text-info" : "";
  return (
    <li className={`${textClass}`}>
      <UserIcon isAdmin={visitor.isAdmin} />
      {visitor.name || visitor.sid}
    </li>
  );
};

const VisitorList: React.FC<PlayerListProps> = () => {
  const { publicUUID } = useAuth();
  const visitors = useSelector((state: RootState) => state.visitors);

  const visitorSIDs = useMemo(() => {
    const visitorSIDs = Object.keys(visitors) as string[];

    if (!publicUUID) return visitorSIDs;

    // sort the visitorSIDs so that the current player (given by publicUUID) is always first, then the admins,
    // then the rest in alphabetical order by name or sid (if name is not set)

    visitorSIDs.sort((a, b) => {
      if (a === publicUUID) return -1;
      if (b === publicUUID) return 1;
      if (visitors[a].isAdmin && !visitors[b].isAdmin) return -1;
      if (!visitors[a].isAdmin && visitors[b].isAdmin) return 1;
      if (visitors[a].name && visitors[b].name) {
        return visitors[a].name.localeCompare(visitors[b].name);
      }
      return visitors[a].sid.localeCompare(visitors[b].sid);
    });
    return visitorSIDs;
  }, [visitors, publicUUID]);

  return (
    <div className={`${styles["player-list-container"]}`}>
      <ul className={`fa-ul`}>
        {visitorSIDs.map((visitorSID) => (
          <Visitor
            visitor={visitors[visitorSID] as VisitorType}
            key={visitorSID}
            isCurrentPlayer={visitorSID === publicUUID}
          />
        ))}
      </ul>
    </div>
  );
};

export default VisitorList;
