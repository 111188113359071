import { configureStore } from "@reduxjs/toolkit";
import * as reducers from "./slices"; // This is a relative import now
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";
import { VisitorsState } from "./slices/visitorsSlice";
import { GameSessionState } from "./slices/gameSessionSlice";
import { SessionState } from "./slices/sessionSlice";
import { UserState } from "./slices/userSlice";
import { BreakoutRoomState } from "./slices/breakoutRoomSlice";

const sagaMiddleware = createSagaMiddleware();

export type RootState = {
  user: UserState;
  session: SessionState;
  visitors: VisitorsState;
  gameSession: GameSessionState;
  breakoutRoom: BreakoutRoomState;
};

const store = configureStore({
  reducer: {
    user: reducers.userReducer,
    gameSession: reducers.gameSessionReducer,
    visitors: reducers.visitorsReducer,
    session: reducers.sessionReducer,
    breakoutRoom: reducers.breakoutRoomReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
  // Disable Redux DevTools in production
  devTools: process.env.NODE_ENV !== "production",
});

sagaMiddleware.run(rootSaga);

export default store;
