import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { SIGNALLING_ENDPOINTS } from "../store/api/urls";

type Callbacks = Record<string, (data: any) => void>;

function useServerSentEvents(callbacks: Callbacks) {
  console.log("[collab] - useServerSentEvents.ts - useServerSentEvents", {
    callbacks,
  });
  const { session_id: sessionId } = useParams();
  const { publicUUID, isLoading } = useAuth();

  useQuery(
    ["sse", sessionId, publicUUID],
    () =>
      new Promise((_, reject) => {
        if (isLoading || !publicUUID || !sessionId) {
          return;
        }

        const sseUrl = SIGNALLING_ENDPOINTS.serverSentEvents({
          sessionId,
          publicUUID,
        });
        const eventSource = new EventSource(sseUrl);

        // TODO: Handle the 'message' event separately if you want.
        eventSource.onmessage = (event) => {
          console.log(
            "[collab] - useServerSentEvents.ts - TODO - handle 'message' event: ",
            { event }
          );
          //   const updatedData = JSON.parse(event.data);
          //   queryClient.setQueryData("someQueryKey", updatedData);
        };

        // Register event listeners for all the callbacks passed in.
        Object.keys(callbacks).forEach((eventName) => {
          eventSource.addEventListener(eventName, (event) => {
            const data = JSON.parse(event.data);
            const callbackFunction = callbacks[eventName];
            if (callbackFunction) {
              callbackFunction(data);
            }
          });
        });

        eventSource.onerror = (err) => {
          console.error("EventSource failed:", err);
          eventSource.close();
          reject(err);
        };

        // Cleanup
        return () => {
          eventSource.close();
        };
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !isLoading && Boolean(publicUUID) && Boolean(sessionId),
    }
  );
}

export default useServerSentEvents;
