// This is a homepage. It'll have a an above the fold section which gives a brief description of the app, and then a section below that will have a button that says "Create a New Game Session" that will take the user to the NewGameSession component. Below the top of fold bit there will be several sections that describe the benefits of the app, and then a section that describes how it works, and then a section that describes the benefits of the app.

// The homepage will have a navbar that has a link to the homepage, a link to the about page, and a link to the contact page. It will also have a link to the pricing page.

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { appName } from "../../utils/constants";
import Layout from "../../components/MarketingPageLayout/MarketingPageLayout";
import styles from "./styles.module.scss";
import Accordion from "react-bootstrap/Accordion";
import WebexLogo from "../../images/webex-logo-icon-non-contained.svg";
import backgroundImage from "../../images/forest-background.svg";
import CloudsComponent from "../../components/CloudsComponent/CloudsComponent";
import CircularButton from "../../components/CircularButton";
// Hero section component
const HeroSection: React.FC = () => {
  // return the hero section which will have the following:
  // 1. A picture on the right side of the screen.
  // 2. Text on the left side of the screen.
  // 3. Take up the full width and height of the screen.
  const [distanceToTopOfPage, setDistanceToTopOfPage] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  // calculate the distance from the top of containerRef to the top of the page and store it in the
  // state variable distanceToTopOfPage
  useEffect(() => {
    const calculateOffset = () => {
      if (containerRef.current) {
        setDistanceToTopOfPage(containerRef.current.offsetTop);
      }
    };

    // If the page has already loaded before our component mounts, just calculate the offset.
    if (document.readyState === "complete") {
      calculateOffset();
    } else {
      // If not, wait for the window's load event to calculate the offset.
      window.addEventListener("load", calculateOffset);
      // add a listener for the resize event so that we can recalculate the offset if the window is resized
      window.addEventListener("resize", calculateOffset);
    }

    // Cleanup the event listener when the component is unmounted.
    return () => {
      window.removeEventListener("load", calculateOffset);
      window.removeEventListener("resize", calculateOffset);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        position: "relative",
        // put this behind the navbar
        top: -distanceToTopOfPage,
        left: 0,
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <CloudsComponent />

      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <div
          className={`d-flex justify-content-center align-items-center vh-100 flex-column text-center`}
        >
          <h1 className="display-4 fw-bold rainbow my-5">
            Short{" "}
            <span
              className="rainbowTextAnimatedActive"
              // apply a random delay to the css animation
              style={{ animationDelay: `${Math.random() * 5}s` }}
            >
              Games
            </span>{" "}
            that{" "}
            <span
              className="rainbowTextAnimatedActive"
              style={{ animationDelay: `${Math.random() * 5}s` }}
            >
              Spark
            </span>{" "}
            Team{" "}
            <span
              className="rainbowTextAnimatedActive"
              style={{ animationDelay: `${Math.random() * 5}s` }}
            >
              Synergy.
            </span>
            <br />
            <small>Perfect for breakouts with 2-4 players.</small>
            {/* Inject <span className="rainbow">more joy</span> into your remote
            team bonding.
            <br />
            Get <span className="rainbow">better engagement</span>. */}
          </h1>
          <div className="col-lg-6 mx-auto">
            {/* <p className="lead mb-5 "> */}
            {/* Effortlessly plan, run and enjoy fun-filled team bonding for your
              remote team with {appName}'s suite of games. Custom built for
              remote teams. */}
            {/* Effortlessly organize and lead engaging team sessions with{" "}
              {appName}'s suite of games. Crafted specifically for remote teams,
              turn every meeting into a moment of fun-filled synergy. Perfect
              for those steering the team's collaborative journey." */}
            {/* </p> */}
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5 ">
              {/* play now button */}
              {/* <Link
            to="/new-session"
            className="btn btn-primary btn-lg px-4 me-sm-3"
          >
            Play Now
          </Link> */}

              {/* Using an anchor tag here because otherwise react-router will intercept the event and we'd need to write custom code to make it work as it normally would if created with normal html */}
              {/* <a
            href="/#benefits"
            className="btn btn-outline-secondary btn-lg px-4"
          >
            Learn More
          </a> */}
              <Link
                // to="https://apphub.webex.com/applications/collab-short-games-for-breakout-sessions"
                to="/webex"
                className="btn btn-light btn-lg px-4"
                // target="_blank"
                // on hover make the background colour black
                onMouseOver={(e) => {
                  e.currentTarget.classList.add("bg-black");
                  e.currentTarget.classList.add("text-white");
                }}
                // on mouse out make the text colour black
                onMouseOut={(e) => {
                  e.currentTarget.classList.remove("bg-black");
                  e.currentTarget.classList.remove("text-white");
                }}
              >
                Play in Webex
                <img
                  src={WebexLogo}
                  alt="Webex Logo"
                  className="btn-logo mx-2"
                  loading={"lazy"}
                  width={35}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Preview section component
const PreviewSection: React.FC = () => {
  // return the preview section which will have the following:
  // 1. A picture on the left side of the screen.
  // 2. Text on the right side of the screen.
  // 3. Take up the full width and height of the screen.
  return (
    <div className={`container-fluid mb-5  `}>
      <div className="overflow-hidde1n">
        <div className="container px-5">
          <img
            src="https://placehold.it/1200x675"
            className="img-fluid border rounded-3 shadow-lg mb-4"
            alt="Example of a game being played."
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

// Scrolling logo section component - this will have a bunch of logos that scroll across the screen from right to left. As placeholders we can use the logos of the following companies: Google, Facebook, Amazon, Netflix, Spotify, and Apple. The logos are all the same size and are all in a row. They scroll across the screen from right to left. They scroll at a constant speed. When they get to the left side of the screen they start again from the right side of the screen.
const ScrollingLogoSection: React.FC = () => {
  const logos: { [key: string]: string } = {
    google: "https://placehold.it/200x100",
    facebook: "https://placehold.it/200x100",
    amazon: "https://placehold.it/200x100",
    netflix: "https://placehold.it/200x100",
    spotify: "https://placehold.it/200x100",
    apple: "https://placehold.it/200x100",
  };

  return (
    <div className="container-fluid mt-5 mb-5">
      <div className={styles.scrollingLogos}>
        {Array(2) // Duplicating the logos twice for smooth looping
          .fill(Object.keys(logos))
          .flat()
          .map((key) => (
            <div key={key + Math.random()}>
              {" "}
              {/* Add randomness to the key to prevent key collisions */}
              <img src={logos[key]} alt={key} />
            </div>
          ))}
      </div>
    </div>
  );
};

const BigDescriptionSection: React.FC = () => {
  return (
    <div className="container mt-5 mb-5">
      <p className="fs-1">
        <strong>
          Bring fun that's designed for remote-first teams to your next get
          together.
        </strong>{" "}
        Games that are built to bring people together. Games that are not
        squeezed into a video call - but designed for it. {appName} brings{" "}
        <span className="rainbow">
          energy, joy, connection, and collaboration.
        </span>
      </p>
    </div>
  );
};

// benefits section component
const BenefitsSection: React.FC = () => {
  // return the benefits section which will have the following:
  // 1. A picture on the left side of the screen.
  // 2. Text on the right side of the screen.
  return (
    <div className="container mt-5" id="benefits">
      <h2 className="h1">Benefits</h2>
      <p>
        Game Night is an app that helps you find the perfect game for your game
        night.
      </p>
    </div>
  );
};

// how it works section component
const HowItWorksSection: React.FC = () => {
  // return the how it works section which will have the following:
  // 1. A picture on the right side of the screen.
  // 2. Text on the left side of the screen.
  return (
    <div className="container mt-5">
      <h2 className="h1">How It Works</h2>
      <p>
        Game Night is an app that helps you find the perfect game for your game
        night.
      </p>
    </div>
  );
};

// FAQ section - entitled "What. The. FAQ."
const FAQSection: React.FC = () => {
  // return the FAQ section which will have the following:
  // 1. A picture on the left side of the screen.
  // 2. Text on the right side of the screen.
  return (
    <div className="container mt-5">
      <h2 className="h1">
        What. <br />
        The. <br />
        FAQ?
      </h2>
      {/* The following is a bootstrap 5.3 accordion with Frequently asked questions */}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Accordion Item #1</Accordion.Header>
          <Accordion.Body>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Accordion Item #2</Accordion.Header>
          <Accordion.Body>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

const HomePage: React.FC = () => {
  const title = `${appName}: Engaging Games for Remote Team Synergy | Elevate Your Virtual Meetings`;
  const description = `Effortlessly organize and lead engaging team sessions with ${appName}'s suite of games. Perfect for remote teams, transform every meeting into a moment of fun-filled synergy. Ideal for those steering team collaborations.`;
  const keywords = [
    appName,
    "team games",
    "remote team bonding",
    "virtual meetings",
    "collaborative games",
    "team synergy",
    "interactive challenges",
    "hand-crafted games",
    "team engagement",
    "webex games",
  ];

  const ogTitle = `${appName}: Elevate Your Remote Team Meetings with Engaging Games`;
  const ogDescription = `Discover ${appName}'s suite of games, meticulously crafted for remote teams. Turn ordinary meetings into memorable moments of collaboration and fun.`;
  const ogImage = ""; // TODO;
  const ogUrl = window.location.href;

  return (
    // this container should take up the full width of the screen
    <Layout
      title={title}
      description={`${description}`}
      keywords={keywords}
      ogTitle={ogTitle}
      ogDescription={ogDescription}
      ogImage={ogImage}
      ogUrl={ogUrl}
      showFooter={true}
    >
      <HeroSection />
      {/* <PreviewSection /> */}
      {/* <ScrollingLogoSection /> */}
      {/* <BigDescriptionSection /> */}
      {/* <BenefitsSection /> */}
      {/* <HowItWorksSection /> */}
      {/* <FAQSection /> */}
    </Layout>
  );
};

export default HomePage;
