import React, { useState, forwardRef, useCallback } from "react";

type CircularButtonProps = React.ComponentProps<"button"> & {
  children: React.ReactNode;
};

const CircularButton = forwardRef<HTMLButtonElement, CircularButtonProps>(
  ({ children, ...props }, externalRef) => {
    const [dimension, setDimension] = useState(0);

    // Ref callback that handles both internal operations and forwards the ref
    const setRef = useCallback(
      (el: HTMLButtonElement | null) => {
        if (typeof externalRef === "function") {
          externalRef(el);
        } else if (externalRef) {
          (
            externalRef as React.MutableRefObject<HTMLButtonElement | null>
          ).current = el;
        }

        // Perform internal operations here
        if (!el) return;

        const { width, height } = el.getBoundingClientRect();
        const maxDimension = Math.max(width, height);

        if (maxDimension === dimension) return;

        setDimension(maxDimension);
      },
      [externalRef, dimension]
    );

    const style = {
      width: `${dimension}px`,
      height: `${dimension}px`,
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };

    return (
      <button ref={setRef} style={style} type="button" {...props}>
        {children}
      </button>
    );
  }
);

export default CircularButton;
