// slices/userSlice.ts
import { createSlice } from "@reduxjs/toolkit";
import { stat } from "fs";

type VisitorTypeBase = {
  isAdmin: boolean;
  sid: string;
  name: string;
};

// there may be other fields here e.g. name, email, age, webexId, etc. and this type should allow for the
// addition of those fields programmatically
export type VisitorType = VisitorTypeBase & {
  [key: string]: string | number | boolean;
};

export interface VisitorsState {
  // the Visitors state will be of the form { "<sid>" : Visitor }
  [sid: string]: VisitorType;
}

const visitorsSlice = createSlice({
  name: "visitors",
  initialState: {} as VisitorsState,
  reducers: {
    // your user-related reducers here
    addVisitor: (state, action) => {
      console.log("[collab] visitorSlice.addVisitor", action.payload);
      const { name, isAdmin, sid } = action.payload;
      state[sid] = { name, isAdmin, sid };
    },

    removeVisitor: (state, action) => {
      const { sid } = action.payload;
      delete state[sid];
    },

    updateVisitor: (state, action) => {
      const { name, isAdmin, sid } = action.payload;
      state[sid] = { name, isAdmin, sid };
    },

    setVisitorMetadata: (state, action) => {
      console.log("[collab] visitorSlice.setVisitorMetadata", action.payload);
      const { sid, metadata } = action.payload;
      if (!sid) {
        console.log(
          "[collab] visitorSlice.setVisitorMetadata no sid",
          state[sid]
        );
        return;
      }

      if (!state[sid]) {
        console.log(
          "[collab] visitorSlice.setVisitorMetadata no state[sid]",
          state[sid]
        );
        // if the visitor doesn't exist, create it with the sid and VisitorType fields
        state[sid] = { sid, isAdmin: false, name: "" };
      }

      Object.keys(metadata).forEach((key) => {
        state[sid][key] = metadata[key];
      });
    },

    setVisitorAdminStatus: (state, action) => {
      console.log(
        "[collab] visitorSlice.setVisitorAdminStatus",
        action.payload
      );
      const { isAdmin, sid } = action.payload;
      if (!state[sid]) {
        console.log(
          "collab] visitorSlice.setVisitorAdminStatus no state[sid]",
          action.payload
        );
        return;
      }
      state[sid].isAdmin = isAdmin;
    },
  },
});

export const { actions: visitorsActions, reducer: visitorsReducer } =
  visitorsSlice;
