import React from "react";
import aaronImage from "../../assets/aaron-founder.jpg";
import Layout from "../../components/MarketingPageLayout/MarketingPageLayout";
import { appName } from "../../utils/constants";

const AboutPage = () => (
  <Layout title={`About ${appName}`} description={`About ${appName}`}>
    <div className="row">
      <div className="col col-lg-8 offset-lg-2 card body-text-card">
        <div className="card-body">
          <div className="row">
            <div className="col mb-5">
              <h1>Discover the magic of {appName}</h1>
            </div>
          </div>
          <div className="row">
            <div className="col lead">
              <p>
                Hey there! Welcome to Chinwag&apos;s newest innovation:{" "}
                {appName}.
              </p>

              <p>
                In the ever-evolving world of remote work, there&apos;s a
                growing need to create meaningful connections beyond screens.
                That's where {appName} comes into play. We&apos;ve designed
                quick yet captivating team-building games that span from a brisk
                minute to a delightful ten.
              </p>

              <p>
                Regardless of your team size - be it a dynamic duo or a
                formidable group of 20,000 - {appName} has something tailored
                for you. Engage in full-team games or explore the closeness of
                breakout rooms, connecting in groups of 2-4. Our goal? To ensure
                every session is not only fun but fosters true collaboration.
              </p>

              <p>
                At Chinwag, we're all about infusing our unique touch into what
                we create, and {appName} is no exception. We envision a world
                where team-building is not just simple, but truly memorable.
                Ready to transform how your team bonds? Join us in this exciting
                journey with {appName}.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex flex-row-reverse">
              <p className="lead text-muted mt-5">
                <strong>Aaron Asaro,</strong> Founder <br />
                <img
                  src={aaronImage}
                  alt="Aaron, founder of Chinwag."
                  className="img-thumbnail rounded-circle mt-3"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default AboutPage;
