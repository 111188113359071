// Please create a TSX component that creates breakout rooms. It should accept an array of player id's and the maximum and minimum number of players per breakout room. I'm using react-bootstrap so feel free to use that in presentation. The user should be able to drag players out of breakout rooms and into other breakout rooms. If a breakout room has fewer than the minimum, or more than the maximum it should highlight the empty slot or overfilled slot. If new players are added to the array once breakout rooms have been created, those should be shown in a list so that they can be dragged into a breakout room. A user should be able to create a new breakout room too, or remove an already empty breakout room.

/* TODO: This will create breakout rooms on the clientside for players to join.
          It'll allow the admin to move players between rooms and then when the
          rooms are finalised it'll send the breakout room data to the server
          and the server will create the game URL and associate that with each
          breakout room.
*/

import React, { useCallback, useEffect, useRef, useState } from "react";
import BreakoutRoom from "./BreakoutRoom";
// import CreateRoomButton from "./CreateRoomButton";
import { useQuery } from "react-query";
import { ENDPOINTS, GameActionEnum } from "../../store/api/urls";
import { ReqHeaders } from "../../store/api/auth";
import { useAuth } from "../../contexts/AuthContext";
import {
  BreakoutRoomCreationModes,
  BreakoutRoomStatus,
  GameSessionStatus,
  GameSessionType,
} from "../../hooks/types";
import { generateBreakoutRooms } from "./util";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { gameSessionActions } from "../../store/slices/gameSessionSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBorderNone } from "@fortawesome/free-solid-svg-icons";

const fetchRoomLimits = async (gameId: string, token: string) => {
  const url = ENDPOINTS.games({ gameId, action: GameActionEnum.ROOM_LIMITS });
  let headers: ReqHeaders = {
    "Content-Type": "application/json",
  };

  const response = await fetch(url, { headers, credentials: "include" }); // You need to replace this with your API endpoint.
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

interface BreakoutRoomAppProps {
  showNewGameModal: boolean;
}

function BreakoutRoomManagement({ showNewGameModal }: BreakoutRoomAppProps) {
  console.log("[collab] - BreakoutRoomManagement - showNewGameModal");
  // get the breakout rooms from the redux store state.gameSession.breakoutRoomPlan
  const rooms = useSelector(
    (state: RootState) => state.gameSession.breakoutRoomPlan
  );

  const gameSession = useSelector((state: RootState) => state.gameSession);

  const breakoutRoomCreationMode = useSelector(
    (state: RootState) => state.gameSession.breakoutRoomCreationMode
  );

  const maxPlayers = useSelector(
    (state: RootState) => state.gameSession.groupSizeMax
  );

  const minPlayers = useSelector(
    (state: RootState) => state.gameSession.groupSizeMin
  );

  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);

  const setRooms = useCallback(
    (rooms: string[][]) => {
      console.log("[collab] - BreakoutRoomManagement - setRooms", rooms);
      dispatch(gameSessionActions.serversideInitialiseBreakoutRoomPlan(rooms));
    },
    [dispatch]
  );

  const [unallocatedPlayers, setUnallocatedPlayers] = useState<string[]>([]);
  // const [maxPlayers, setMaxPlayers] = useState(null);
  // const [minPlayers, setMinPlayers] = useState(null);
  const { token } = useAuth();

  const removeRoom = useCallback(
    (index: number) => {
      console.log("[collab] - BreakoutRoomManagement - removeRoom", index);
      const newRooms = [...rooms];
      newRooms.splice(index, 1);
      setRooms(newRooms);
    },
    [rooms, setRooms]
  );

  const gameId = gameSession?.gameId;
  const playerIds = gameSession?.players;

  const handleAutoAssign = useCallback(() => {
    if (!maxPlayers || !minPlayers || !unallocatedPlayers) return;

    console.log("[collab] - BreakoutRoomManagement - handleAutoAssign");

    const result = generateBreakoutRooms({
      playerIds: unallocatedPlayers,
      maxPlayers,
      minPlayers,
      existingRooms: rooms,
    });

    const newRooms = result?.rooms;
    const updatedUnallocatedPlayers = result?.unallocated;

    if (
      JSON.stringify(unallocatedPlayers) !==
      JSON.stringify(updatedUnallocatedPlayers)
    ) {
      setUnallocatedPlayers(updatedUnallocatedPlayers);
    }

    if (JSON.stringify(newRooms) !== JSON.stringify(rooms)) {
      setRooms(newRooms);
    }
  }, [maxPlayers, minPlayers, unallocatedPlayers, rooms, setRooms]);

  // when unallocatedPlayers change we need to run the handleAutoAssign function
  // provided there are players to assign
  useEffect(() => {
    if (
      breakoutRoomCreationMode ===
      BreakoutRoomCreationModes.AUTOMATIC_ASSIGNMENT
    ) {
      return;
    }

    console.log(
      "[collab] - BreakoutRoomManagement - unallocatedPlayers",
      unallocatedPlayers
    );

    if (unallocatedPlayers.length > 0) {
      console.log(
        "[collab] - BreakoutRoomManagement - unallocatedPlayers.length > 0"
      );
      handleAutoAssign();
    }
  }, [
    unallocatedPlayers,
    minPlayers,
    maxPlayers,
    breakoutRoomCreationMode,
    handleAutoAssign,
  ]);

  useEffect(() => {
    if (
      breakoutRoomCreationMode !==
      BreakoutRoomCreationModes.AUTOMATIC_ASSIGNMENT
    ) {
      return;
    }

    console.log("[collab] - BreakoutRoomManagement - playerIds", playerIds);

    // If we have no players, everyone might have left the game so we should reset
    // TODO: I think there's a problem here. If the admin has just refreshed the page, then
    // the playerIds will be null, but the gameSession.players will be populated. So
    // we need to wait for the initial serverside sync to complete before we can
    // check if there are players in the game.
    // The serverside should be the one to remove players from rooms when they leave
    if (!playerIds || playerIds.length === 0) {
      console.log("[collab] - BreakoutRoomManagement - !playerIds");
      // setUnallocatedPlayers([]);
      // setRooms([]);
      return;
    }

    // If we have no rooms, all players are unallocated
    if (rooms.length === 0) {
      console.log("[collab] - BreakoutRoomManagement - rooms.length === 0");
      setUnallocatedPlayers(playerIds);
      return;
    }

    // A player might have left the game, so we need to check if they're in a room and remove them
    const updatedRooms = rooms.map((room) =>
      room.filter((playerId: string) => playerIds.includes(playerId))
    );
    console.log(
      "[collab] - BreakoutRoomManagement - updatedRooms",
      updatedRooms
    );
    setRooms(updatedRooms);

    // If we have rooms, we need to check which players are unallocated
    const allocatedPlayers = updatedRooms.flat();
    let unallocatedPlayers = playerIds.filter(
      (playerId) => !allocatedPlayers.includes(playerId)
    );

    // The leaving player might have left a room below the minimum number of players,
    // so we need to move those players to the unallocated list and remove the room
    updatedRooms.forEach((room, index) => {
      if (minPlayers && room.length < minPlayers) {
        unallocatedPlayers = [...unallocatedPlayers, ...room];
        removeRoom(index);
      }
    });

    setUnallocatedPlayers(unallocatedPlayers);
  }, [
    playerIds,
    gameId,
    breakoutRoomCreationMode,
    rooms,
    setRooms,
    minPlayers,
    removeRoom,
  ]);

  const {
    data: roomLimits,
    isLoading,
    isError,
  } = useQuery(
    ["roomLimits", gameId],
    () => {
      console.log("[collab] - BreakoutRoomManagement - useQuery - roomLimits");
      if (gameId && token) return fetchRoomLimits(gameId, token);
    },
    { refetchOnWindowFocus: false } // We don't want to refetch when the user switches tabs
  );

  useEffect(() => {
    if (roomLimits) {
      console.log("[collab] - BreakoutRoomManagement - roomLimits", roomLimits);
      dispatch(
        gameSessionActions.setGroupSize({
          min: roomLimits.min_players,
          max: roomLimits.max_players,
        })
      );
    }
  }, [dispatch, roomLimits]);

  const { isAdmin } = useSelector((state: RootState) => state.session);

  const breakoutRoom = useSelector((state: RootState) => state.breakoutRoom);

  if (!isAdmin) {
    return null;
  }

  if (showNewGameModal) {
    return null;
  }

  if (
    gameSession.status === GameSessionStatus.STARTED &&
    breakoutRoom.gameUrl &&
    breakoutRoom.status === BreakoutRoomStatus.STARTED
  ) {
    // if the game has started for everyone and this admin is participating then
    // we shouldn't show breakout room management
    return null;
  }

  if (!gameId || !playerIds || !maxPlayers || !minPlayers) return null;

  if (isLoading) return <p>Loading...</p>;

  if (isError) return <p>Error fetching room limits.</p>;

  return (
    <>
      {unallocatedPlayers.length > 0 && (
        <div className="row mt-5 align-items-center">
          <div className="col text-start">
            <BreakoutRoom
              roomName={`Unallocated players`}
              players={unallocatedPlayers}
              maxPlayers={maxPlayers}
              minPlayers={minPlayers}
              removeRoom={() => {}}
              showRoomControls={false}
            />
          </div>
        </div>
      )}
      {rooms.length > 0 && (
        <>
          <div className="position-relative">
            <div
              className="position-absolute top-0 start-50 translate-middle rounded-pill badge text-bg-dark p-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                // scroll the user such that the containerRef component is at the top of the screen
                containerRef.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
            >
              <FontAwesomeIcon icon={faBorderNone} size="5x" />
            </div>
          </div>

          <div className="row mt-5 mb-5" ref={containerRef}>
            <div className="col text-center">
              <h3>Breakout rooms</h3>
            </div>
          </div>

          <div className="row">
            <div className="col pb-5">
              <div className="row row-cols-3 g-4">
                {rooms.map((room, index) => (
                  <BreakoutRoom
                    key={`room_${index}`}
                    roomName={`Room ${index + 1}`}
                    players={room}
                    maxPlayers={maxPlayers}
                    minPlayers={minPlayers}
                    removeRoom={() => removeRoom(index)}
                    showRoomControls={
                      breakoutRoomCreationMode !==
                      BreakoutRoomCreationModes.SELF_SELECTION
                    }
                  />
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default BreakoutRoomManagement;
