export const appName = "Collab";

export const companyDetails = {
  name: "KC Dynamics Ltd",
  registered_with: "England and Wales",
  number: "11919334",
  address_full: "83 Ducie Street, Manchester, M1 2JQ, United Kingdom",
  help_email: "collab@getchinwag.com",
  product_name: appName,
};

export const localStorageEnum = {
  USERNAME: "username",
};
