import { ENDPOINTS, AuthenticationActionEnum } from "./urls";
import { TokenEnum } from "../../contexts/AuthContext";

export const checkTokenValidity = async (token: string | null) => {
  if (!token) {
    throw new Error("No token");
  }

  let headers: ReqHeaders = {
    "Content-Type": "application/json",
  };

  const response = await fetch(
    ENDPOINTS.authentication({ action: AuthenticationActionEnum.VALIDATE }),
    {
      method: "POST",
      headers,
      credentials: "include",
    }
  );

  if (!response.ok) {
    throw new Error("Token check failed");
  }

  const data = await response.json();
  return data;
};

// get an auth token from server. The server will issue a token that lasts 24 hours
// we send the old token to the server so that we can extract the user id from it
// and then issue a new token with the same user id. That in turn just helps us track sessions
// across time - for example of a token expires at 12:04 and the user is listening for messages
// with the guid issued on that token, we don't want to the messages to stop flowing
export const fetchVisitorAuthToken = async ({
  oldToken = "",
}: { oldToken?: string | null } = {}) => {
  const response = await fetch(
    ENDPOINTS.authentication({ action: AuthenticationActionEnum.VISITOR }),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ old_token: oldToken || "" }),
    }
  );
  const data = await response.json();
  const { access_token: accessToken, expires, public_uuid: publicUUID } = data;

  if (!accessToken) {
    throw new Error("No access token");
  }
  if (!expires) {
    throw new Error("No token expiration");
  }
  if (!publicUUID) {
    throw new Error("No public uuid");
  }

  return { accessToken, expires, publicUUID };
};

export const checkAndRefreshVisitorToken = async (
  authenticateVisitor: () => Promise<void>
) => {
  // Check local storage or cookie for token
  const token = localStorage.getItem(TokenEnum.ACCESS_TOKEN);
  const expires = localStorage.getItem(TokenEnum.TOKEN_EXPIRES);
  if (!token || !expires) {
    await authenticateVisitor();
  } else {
    // Optionally, validate if the token is about to expire soon.
    // If the token is about to expire, then refresh or re-authenticate the visitor.
    const now = new Date();
    // expires is a timestamp in ms
    const expiresDate = new Date(parseInt(expires));

    // check if valid date
    if (isNaN(expiresDate.getTime())) {
      await authenticateVisitor();
    }

    // check if expired or about to expire
    // if the token is about to expire in less than an hour, then refresh it
    if (expiresDate.getTime() < now.getTime() + 1000 * 60 * 60) {
      await authenticateVisitor();
    }
  }
};

export type ReqHeaders = { [key: string]: string };

// interface AuthHeaderParams {
//   headers?: ReqHeaders;
//   token: string | null;
// }

// type addAuthorisationHeaderType = (params: AuthHeaderParams) => ReqHeaders;

// export const addAuthorisationHeader: addAuthorisationHeaderType = ({
//   headers = {},
//   token,
// }) => {
//   if (token) {
//     return {
//       ...headers,
//       Authorization: `Bearer ${token}`,
//     };
//   }
//   return headers;
// };
