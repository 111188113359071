// This componente has two primary sections.
// The top section is the description of the game.
//      The left side shows the game preview image
//      The right gives a short description.
// The bottom section is the game session itself
//     It shows a Join button if the user is not in the session
//     It shows a Leave button if the user is in the session
//     It shows a Start button if the user is an admin and the session is not started
//     It shows the list of players that have joined the session

import React, { useEffect, useState } from "react";

import { BreakoutRoomStatus, GameSessionStatus } from "../../hooks/types";

import GameSessionHeader from "./GameSessionHeader";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import EmbeddedGame from "../EmbeddedGame/EmbeddedGame";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { ButtonGroup, Card, Col, Row } from "react-bootstrap";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";

import { breakoutRoomActions } from "../../store/slices";

function InGameControls({
  show,
  setShow,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
}) {
  const toggleShow = () => setShow(!show);
  const dispatch = useDispatch();

  return (
    <>
      <Offcanvas show={show} onHide={toggleShow} placement={"top"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title> </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ height: "100%" }}
          >
            <div className="col-md-4">
              <Card>
                <Button
                  variant="danger"
                  onClick={() => {
                    dispatch(
                      breakoutRoomActions.requestStatusChange(
                        BreakoutRoomStatus.ENDED
                      )
                    );
                  }}
                >
                  Exit Game
                </Button>
              </Card>
            </div>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function OffCanvasHandle({
  show,
  setShow,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
}) {
  const [height, setHeight] = useState(10);
  if (show) {
    return null;
  }

  const handleOnMouseEnter = () => {
    setHeight(20);
  };

  const handleOnMouseLeave = () => {
    setHeight(10);
  };

  return (
    <div
      style={{
        height: `${height}px`,
      }}
      className={`${styles["top-handle"]}`}
      onClick={() => setShow(!show)}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <FontAwesomeIcon icon={faEllipsis} />
    </div>
  );
}

interface GameSessionProps {
  onNewGame: () => void;
}

const GameSession: React.FC<GameSessionProps> = ({ onNewGame }) => {
  const gameSession = useSelector((state: RootState) => state.gameSession);
  const breakoutRoom = useSelector((state: RootState) => state.breakoutRoom);

  const [show, setShow] = useState(true);

  // when the embedded game is first presented we should show the InGameControls for a few seconds
  // then hide them. That will help the user understand that they can control the game.
  useEffect(() => {
    if (breakoutRoom.status !== BreakoutRoomStatus.STARTED) {
      return;
    }
    const timeoutId = setTimeout(() => {
      setShow(false);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [breakoutRoom.status]);

  if (!gameSession.gameId) {
    return null;
  }

  const { gameId } = gameSession;

  if (
    gameSession &&
    gameSession.status === GameSessionStatus.STARTED &&
    breakoutRoom.gameUrl &&
    breakoutRoom.status === BreakoutRoomStatus.STARTED
  ) {
    return (
      <>
        <EmbeddedGame />
        <OffCanvasHandle show={show} setShow={setShow} />
        <InGameControls show={show} setShow={setShow} />
      </>
    );
  }

  return (
    <GameSessionHeader
      gameId={gameId}
      gameSession={gameSession}
      onNewGame={onNewGame}
    />
  );
};

export default GameSession;
