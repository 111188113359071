import { useSelector } from "react-redux";
import { RootState } from "../../store";
import NewGameButton from "../NewGameButton/NewGameButton";
import LoadingMessage from "../LoadingMessage/LoadingMessage";
import { useEffect, useRef, useState } from "react";

// The component is called AwaitingGamePlaceholder and is used in the SessionPage component.

interface AwaitingGamePlaceholderProps {
  showNewGameModal: () => void;
}

const AwaitingGamePlaceholder: React.FC<AwaitingGamePlaceholderProps> = ({
  showNewGameModal,
}) => {
  const gameSession = useSelector((state: RootState) => state.gameSession);
  const { isAdmin } = useSelector((state: RootState) => state.session);
  const containerRef = useRef<HTMLDivElement>(null);
  const [minBgHeight, setMinBgHeight] = useState<number | null>(null);

  // this will set the height of the background image to be the height of the top of the current div
  // to the bottom of the viewport
  useEffect(() => {
    console.log("handleResize");
    if (!containerRef.current) {
      console.log("handleResize - no container ref");
      return;
    }

    const height = window.innerHeight - containerRef.current.offsetTop;
    setMinBgHeight(height);
  }, [containerRef]);

  if (gameSession.gameId) {
    return null;
  }

  if (!isAdmin) {
    return (
      <div
        className="row"
        ref={containerRef}
        style={{
          height: minBgHeight ? `${minBgHeight}px` : "auto",
        }}
      >
        <div className="col-3 text-center text-light position-absolute top-50 start-50 translate-middle bg-info rounded-1 p-3">
          <LoadingMessage
            message="Waiting for host to setup a game"
            showSpinner={false}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className="row"
      ref={containerRef}
      style={{
        height: minBgHeight ? `${minBgHeight}px` : "auto",
      }}
    >
      <div className="col-3">
        <NewGameButton showNewGameModal={showNewGameModal} />
      </div>
    </div>
  );
};

export default AwaitingGamePlaceholder;
