const API_HOST = process.env.REACT_APP_API_HOST;
const SIGNALLING_HOST = process.env.REACT_APP_SIGNALLING_HOST;

export const API_ENDPOINT = `${API_HOST}/api/v1`;
export const SIGNALLING_ENDPOINT = `${SIGNALLING_HOST}/api/v1`;

export const AuthenticationActionEnum = {
  VISITOR: "visitor",
  MEMBER: "member",
  VALIDATE: "validate",
};

export const BreakoutRoomActionsEnum = {
  STATUS: "status",
};

type BreakoutRoomActionValue =
  (typeof BreakoutRoomActionsEnum)[keyof typeof BreakoutRoomActionsEnum];

type AuthenticationActionValue =
  (typeof AuthenticationActionEnum)[keyof typeof AuthenticationActionEnum];

type AuthenticationUrlOptions = {
  action?: AuthenticationActionValue;
};

export const SessionActionEnum = {
  GAME_SESSIONS: "game-sessions",
  BREAKOUT_ROOMS: "breakout-rooms",
};

export const GameActionEnum = {
  FILTER_OPTIONS: "filter_options",
  ROOM_LIMITS: "room_limits",
  DETAILS: "details",
};

export const GameSessionActionEnum = {
  PLAYERS: "players",
  ADD: "add",
  REMOVE: "remove",
  STATUS: "status",
  BREAKOUT_ROOMS: "breakout-rooms",
  BREAKOUT_ROOM_PLAN: "breakout-room-plan",
};

export const BreakoutRoomPlanEnum = {
  ADD: "add",
  REMOVE: "remove",
};

type SessionActionValue =
  (typeof SessionActionEnum)[keyof typeof SessionActionEnum];

type GameActionValue = (typeof GameActionEnum)[keyof typeof GameActionEnum];

export type GameSessionActionValue =
  (typeof GameSessionActionEnum)[keyof typeof GameSessionActionEnum];

type SessionsUrlOptions = {
  sessionId?: string;
  action?: SessionActionValue;
};

type GameUrlOptions = {
  gameId?: string;
  action?: GameActionValue;
};

type GameSessionsUrlOptions = {
  sessionId: string;
  gameSessionId?: string;
  action?: GameSessionActionValue;
};

type PeopleUrlOptions = {
  uuid?: string;
  action?: string;
};

export const ENDPOINTS = {
  authentication: ({ action }: AuthenticationUrlOptions = {}) => {
    let url = `${API_ENDPOINT}/authentication/token`;
    if (action) {
      url = `${url}/${action}`;
    }
    return url;
  },
  games: ({ gameId, action }: GameUrlOptions = {}) => {
    let url = `${API_ENDPOINT}/games`;
    if (gameId) {
      url = `${url}/${gameId}`;
    }
    if (action) {
      url = `${url}/${action}`;
    }
    return url;
  },
  people: ({ uuid, action }: PeopleUrlOptions) => {
    let url = `${API_ENDPOINT}/people`;
    if (uuid) {
      url = `${url}/${uuid}`;
    }
    if (action) {
      url = `${url}/${action}`;
    }
    return url;
  },
  sessions: ({ sessionId, action }: SessionsUrlOptions = {}) => {
    let url = `${API_ENDPOINT}/sessions`;
    if (sessionId) {
      url = `${url}/${sessionId}`;
    }
    if (action) {
      url = `${url}/${action}`;
    }
    return url;
  },
  breakoutRooms: ({
    breakoutRoomId,
    sessionId,
    gameSessionId,
    action,
  }: {
    breakoutRoomId?: string;
    sessionId: string;
    gameSessionId: string;
    action?: BreakoutRoomActionValue;
  }) => {
    let url = `${API_ENDPOINT}/sessions/${sessionId}/game-sessions/${gameSessionId}/breakout-rooms`;
    if (breakoutRoomId) {
      url = `${url}/${breakoutRoomId}`;
    }
    if (action) {
      url = `${url}/${action}`;
    }
    return url;
  },
  gameSessions: ({
    sessionId,
    action,
    gameSessionId,
  }: GameSessionsUrlOptions) => {
    let url = `${API_ENDPOINT}/sessions/${sessionId}/game-sessions`;
    if (gameSessionId) {
      url = `${url}/${gameSessionId}`;
    }
    if (action) {
      url = `${url}/${action}`;
    }
    return url;
  },
};

export const SIGNALLING_ENDPOINTS = {
  serverSentEvents: ({
    sessionId,
    publicUUID,
  }: {
    sessionId: string;
    publicUUID: string;
  }) => {
    let url = `${SIGNALLING_ENDPOINT}/messages/${sessionId}/server-sent-events/${publicUUID}`;
    return url;
  },

  messages: ({
    sessionId,
    destination,
  }: {
    sessionId: string;
    destination: string;
  }) => {
    let url = `${SIGNALLING_ENDPOINT}/messages/${sessionId}/${destination}`;
    return url;
  },
};
