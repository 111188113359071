import React from "react";
import { LinkProps } from "react-router-dom";

interface CustomLinkProps extends LinkProps {
  to: string;
}

const CustomLink: React.FC<CustomLinkProps> = ({ to, ...props }) => {
  const baseUrl = window.location.origin;
  const fullUrl = `${baseUrl}${to}`;

  return (
    <a href={to} {...props}>
      {fullUrl}
    </a>
  );
};

export default CustomLink;
