import { useEffect, useState } from "react";
import { PostMessageEvents } from "../types";

const useViewportHeight = () => {
  const [viewportHeight, setViewportHeight] = useState(0);

  useEffect(() => {
    console.log("[collab] - useViewportHeight.ts - useViewportHeight");
    const handleMessage = (event: MessageEvent) => {
      // Add validation to ensure the message is coming from your parent frame
      const { data } = event;
      if (data && data.messageType === PostMessageEvents.VIEWPORT_HEIGHT) {
        console.log("[collab] - useViewportHeight.ts - handleMessage", {
          data,
        });
        setViewportHeight(data.payload);
      }
    };

    // Listen for messages from the parent frame
    window.addEventListener("message", handleMessage);

    return () => {
      // Cleanup
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return viewportHeight;
};

export default useViewportHeight;
