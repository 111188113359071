/*
This creates the footer component :
*/

import React from "react";
import { appName } from "../../utils/constants";

import { Link } from "react-router-dom";
import ColourModeDropdown from "./ColourModeDropdown";
import {
  faCircleHalfStroke,
  faSun,
  faMoon,
} from "@fortawesome/free-solid-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";
import styles from "./styles.module.scss";
import Logo from "../../images/logo.svg";

library.add(faCircleHalfStroke, faSun, faMoon);

const Footer: React.FC = () => {
  return (
    <footer className="footer mt-5 pt-5 pb-5">
      <div className="container">
        <div className="row text-lg-left ">
          <div className="col-sm-12 col-md-12 col-lg-2 col-xl-2 text-lg-start text-sm-center">
            <a href="/" className="navbar-brand">
              <img
                src={Logo}
                alt={`${appName} Logo`}
                className={`d-inline-block img-thumbnail border-0 ${styles.spin} `}
                loading="lazy"
                width="80"
              />
            </a>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg col-xl">
            <h5> Resources </h5>
            <ul className="nav flex-column text-lg-left ">
              {/* <li className="nav-item">
                <Link className="nav-link text-muted" to="/new-session">
                  Play Now
                </Link>
              </li> */}

              <li className="nav-item">
                <Link className="nav-link text-muted" to="/webex">
                  Webex integration
                </Link>
              </li>

              <li className="nav-item ">
                <a
                  className="nav-link text-muted"
                  href="https://status.getchinwag.com/"
                >
                  Status
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link text-muted" href="/pricing">
                  Pricing
                </a>
              </li> */}
            </ul>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg col-xl">
            <h5> Chinwag </h5>
            <ul className="nav flex-column text-lg-left ">
              <li className="nav-item ">
                <a
                  className="nav-link text-muted"
                  href="https://www.getchinwag.com/about"
                  rel="nofollow"
                >
                  About us
                </a>
              </li>
              <li className="nav-item ">
                <a
                  className="nav-link text-muted"
                  href="https://blog.getchinwag.com"
                  rel="nofollow"
                >
                  Blog
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg col-xl">
            <h5> Get in touch </h5>
            <ul className="nav flex-column text-lg-left ">
              <li className="nav-item ">
                <a
                  className="nav-link text-muted"
                  id="contact-link"
                  href={`mailto:${appName.toLowerCase()}@getchinwag.com`}
                  rel="nofollow"
                >
                  {appName.toLowerCase()}@getchinwag.com
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-1 col-xl-1">
            <ul className="list-unstyled">
              <div className="float-end">
                <ColourModeDropdown />
              </div>
            </ul>
          </div>
        </div>
        <div className="row text-muted mt-5" style={{ fontSize: "0.8rem" }}>
          <div className="col-sm text-left">
            <span className="navbar-text text-nowrap">© KC Dynamics Ltd.</span>
          </div>
          <div className="col-sm">
            <ul className="list-inline float-end">
              <li className="nav-item list-inline-item">
                {/* Terms link */}
                <Link className="nav-link text-muted" to="/legal/terms">
                  Terms &amp; Conditions
                </Link>
              </li>
              <li className="nav-item list-inline-item">
                <Link className="nav-link text-muted" to="/legal/privacy">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
