import React from "react";

let loadingMessages = [
  "Splicing DNA in Genetic Algorithms",
  "Initialising Recursive Procedures",
  "Quantizing Neural Network",
  "Deploying Software Agents",
  "Allocating Latent Semantic Space",
  "Normalising Database Schema",
  "Generating Pseudo-random Hashes",
  "Compiling Turing Machines",
  "Optimizing Eigenvectors",
  "Updating Bayesian Classifier",
  "Synchronizing Bipartite Graphs",
  "Executing Genetic Algorithm",
  "Balancing Binary Search Trees",
  "Decoding Cryptographic Protocol",
  "Executing Garbage Collection",
  "Training Convolutional Networks",
];

interface Props {
  message: string;
  showSpinner?: boolean;
}

const LoadingMessage: React.FC<Props> = ({ message, showSpinner = true }) => {
  const [dotIndex, setDotIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setDotIndex((prevIndex) => (prevIndex + 1) % 4);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const dotStyle = {
    color: dotIndex >= 1 ? "inherit" : "transparent",
  };

  const dot2Style = {
    color: dotIndex >= 2 ? "inherit" : "transparent",
  };

  const dot3Style = {
    color: dotIndex >= 3 ? "inherit" : "transparent",
  };

  return (
    // make sure this div flows down the page so that the loading message is always at the bottom
    <div className="d-flex align-items-center flex-column">
      {showSpinner && (
        <div className="spinner-border mb-5" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}

      <div className="text-center">
        <p className="lead mb-0">
          {message}
          <span className="ms-1" style={dotStyle}>
            .
          </span>
          <span className="ms-1" style={dot2Style}>
            .
          </span>
          <span className="ms-1" style={dot3Style}>
            .
          </span>
        </p>
      </div>
    </div>
  );
};

export default LoadingMessage;
