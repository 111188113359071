import {
  takeEvery,
  call,
  all,
  select,
  put,
  takeLatest,
  take,
  race,
  delay,
  CallEffect,
  SelectEffect,
} from "redux-saga/effects";

import { gameSessionActions } from "../slices/gameSessionSlice";
import { GameSessionStatus } from "../../hooks/types";
import { RootState } from "..";
import {
  changeGameSessionStatusServerside,
  initBreakoutRoomPlan,
  setupBreakoutRooms,
  updateBreakoutRoomPlan,
} from "../api/gameSessions";
import { AnyAction } from "@reduxjs/toolkit";
import { PostMessageTypes } from "../../types";
import { VisitorsState } from "../slices/visitorsSlice";
import { send } from "process";
import {
  BreakoutRoomPlanEnum,
  ENDPOINTS,
  GameSessionActionEnum,
} from "../api/urls";
import { postMessageToParent } from "../../utils";

function* lockGameSessionServerside(
  action: ReturnType<typeof gameSessionActions.serversideLockGameSession>
) {
  console.log("[collab] - gameSessionSaga - lockGameSessionServerside", action);

  const requestedStatus = action.payload;

  const user: RootState["user"] = yield select(
    (state: RootState) => state.user
  );
  const { token } = user;

  const gameSessionId: string = yield select(
    (state: RootState) => state.gameSession.gameSessionId
  );

  const sessionId: string = yield select(
    (state: RootState) => state.session.sessionId
  );

  if (!token || !sessionId) return;

  try {
    yield put(gameSessionActions.setStatus(GameSessionStatus.LOCKING));

    // used client-side to determine which admin initiated the lock - as it is that admin
    // who should send the breakout rooms to the server
    yield put(gameSessionActions.setInitiatedLock(true));

    yield call(changeGameSessionStatusServerside, {
      gameSessionId,
      sessionId,
      token,
      status: requestedStatus,
    });
  } catch (error) {
    console.error("Error updating game session status on server:", error);
    // reset the game session status to unstarted
    yield put(gameSessionActions.setStatus(GameSessionStatus.UNSTARTED));
    yield put(gameSessionActions.setInitiatedLock(false));
  }
}

/*
 * Description.
 * This saga is responsible for sending the breakout room plan to the server when the game session status is
 * changed to locked by the signalling server. Only the primary admin can send the breakout rooms to the server.
 * TODO: This is redundant as the breakout rooms are now already stored serverside. Really what this should do is
 * send a request to the server to create the breakout rooms based on what's already in the serverside store.
 */
function* sendBreakoutRoomsToServer(
  action: ReturnType<typeof gameSessionActions.setStatus>
) {
  const LOG_PREFIX = "[sendBreakoutRoomsToServer]";

  console.log(`${LOG_PREFIX} started with action:`, action);

  // check to see if the user is an admin for this session
  const isAdmin: boolean = yield select(
    (state: RootState) => state.session.isAdmin
  );
  console.log(`${LOG_PREFIX} Is user admin?`, isAdmin);
  if (!isAdmin) return;

  // check to see if the game session was locked by this admin
  const initiatedLock: GameSessionStatus = yield select(
    (state: RootState) => state.gameSession.initiatedLock
  );

  console.log(`${LOG_PREFIX} initiated lock:`, initiatedLock);
  if (!initiatedLock) return;

  // Check that the status the signalling server is changing to is STARTING (i.e. the game session is locked to new users)
  console.log(
    `${LOG_PREFIX} Action payload !== GameSessionStatus.STARTING:`,
    action.payload,
    action.payload !== GameSessionStatus.STARTING
  );
  if (action.payload !== GameSessionStatus.STARTING) return;

  // send the breakout rooms to the server
  const user: RootState["user"] = yield select(
    (state: RootState) => state.user
  );
  console.log(`${LOG_PREFIX} User:`, user);

  const { token } = user;

  const gameId: string = yield select(
    (state: RootState) => state.gameSession.gameId
  );

  const gameSessionId: string = yield select(
    (state: RootState) => state.gameSession.gameSessionId
  );
  console.log(`${LOG_PREFIX} Game Session ID:`, gameSessionId);

  const breakoutRoomPlan: string[][] = yield select(
    (state: RootState) => state.gameSession.breakoutRoomPlan
  );
  console.log(`${LOG_PREFIX} Breakout Room Plan:`, breakoutRoomPlan);

  const sessionId: string = yield select(
    (state: RootState) => state.session.sessionId
  );
  console.log(`${LOG_PREFIX} Session ID:`, sessionId);

  if (!token || !sessionId || breakoutRoomPlan.length < 1 || !gameSessionId) {
    console.warn(
      `${LOG_PREFIX} Missing token, session ID, breakout rooms, or game session ID`
    );
    return;
  }

  try {
    console.log(`${LOG_PREFIX} Sending breakout rooms to the server...`);
    yield call(setupBreakoutRooms, {
      gameSessionId,
      sessionId,

      breakoutRoomPlan,
      gameId,
    });
    console.log(`${LOG_PREFIX} Breakout rooms sent successfully!`);

    yield call(changeGameSessionStatusServerside, {
      gameSessionId,
      sessionId,
      token,
      status: GameSessionStatus.STARTED,
    });

    // We need to send any parent the breakout rooms with all of the public details of the users in each room
    // so that the parent can send the relevant information to the hosting video platform. So loop through each
    // breakout room and get the public details of each user in that room and send it to the parent.
    // breakoutRooms is an array of arrays in the following form:
    // [['235c0543-4d37-4453-b933-d7cd1ea1285b', '24a7be02-f348-441c-ad5d-93bbc4afacf5']]
    const visitors: VisitorsState = yield select(
      (state: RootState) => state.visitors
    );

    const breakoutRoomsWithDetails = [];
    for (const room of breakoutRoomPlan) {
      const roomWithDetails = [];
      for (const userId of room) {
        const visitor = visitors[userId];
        if (!visitor) {
          console.warn(
            `${LOG_PREFIX} Visitor with ID ${userId} not found in visitors state`
          );
          continue;
        }
        roomWithDetails.push(visitor);
      }
      breakoutRoomsWithDetails.push(roomWithDetails);
    }

    console.log(
      "[collab] - gameSessionSaga - Sending breakout rooms to parent:",
      breakoutRoomsWithDetails
    );

    // send a window.postMessage to the parent window to let it know that the game session has started
    window.parent.postMessage(
      {
        messageType: PostMessageTypes.BREAKOUT_ROOMS_ASSIGNED,
        payload: {
          breakoutRooms: breakoutRoomsWithDetails,
        },
      },
      "*"
    );
  } catch (error) {
    console.error(
      `${LOG_PREFIX} Error sending breakout rooms to server:`,
      error
    );
    // reset the game session status to unstarted
    yield put(gameSessionActions.setStatus(GameSessionStatus.UNSTARTED));
    yield put(gameSessionActions.setInitiatedLock(false));
  }
}

function* unlockGameSessionServerside(
  action: ReturnType<typeof gameSessionActions.setStatus>
) {
  /*
   * If the admin that attempted to kick off the game has their own session change to UNSTARTED, then we should
   * unlock the game session on the server. This will allow the admin to try again and more users to join the game session.
   */
  console.log(
    "[collab] - gameSessionSaga - unlockGameSessionServerside",
    action
  );

  if (action.payload !== GameSessionStatus.UNSTARTED) return;

  // check to see if the user is an admin for this session
  const isAdmin: boolean = yield select(
    (state: RootState) => state.session.isAdmin
  );
  if (!isAdmin) return;

  // check to see if the game session is in the GameSessionStatus.LOCKING state
  const gameSessionStatus: GameSessionStatus = yield select(
    (state: RootState) => state.gameSession.status
  );
  if (
    gameSessionStatus !== GameSessionStatus.LOCKING &&
    gameSessionStatus !== GameSessionStatus.STARTING
  )
    return;

  // send the breakout rooms to the server
  const user: RootState["user"] = yield select(
    (state: RootState) => state.user
  );
  const { token } = user;

  const gameSessionId: string = yield select(
    (state: RootState) => state.gameSession.gameSessionId
  );

  const sessionId: string = yield select(
    (state: RootState) => state.session.sessionId
  );

  if (!token || !sessionId || !gameSessionId) return;

  try {
    yield put(gameSessionActions.setStatus(GameSessionStatus.LOCKING));
    yield call(changeGameSessionStatusServerside, {
      gameSessionId,
      sessionId,
      token,
      status: GameSessionStatus.UNSTARTED,
    });
  } catch (error) {
    console.error("Error sending breakout rooms to server:", error);
    // reset the game session status to unstarted
    yield put(gameSessionActions.setStatus(GameSessionStatus.UNSTARTED));
    yield put(gameSessionActions.setInitiatedLock(false));
  }
}
function isSetStatusAction(
  action: AnyAction
): action is ReturnType<typeof gameSessionActions.setStatus> {
  return action.type === gameSessionActions.setStatus.type;
}

type CombinedActions =
  | ReturnType<typeof gameSessionActions.setStatus>
  | ReturnType<typeof gameSessionActions.setGameSession>;

function isSetGameSessionAction(
  action: AnyAction
): action is ReturnType<typeof gameSessionActions.setGameSession> {
  return action.type === gameSessionActions.setGameSession.type;
}

function* unlockIfLongWait(action: CombinedActions) {
  let currentStatus: GameSessionStatus;

  if (isSetStatusAction(action)) {
    currentStatus = action.payload;
  } else if (isSetGameSessionAction(action)) {
    currentStatus = action.payload.status;
  } else {
    console.log(
      "[collab] - gameSessionSaga - Unsupported action type:",
      action
    );
    return;
  }

  if (
    currentStatus !== GameSessionStatus.LOCKING &&
    currentStatus !== GameSessionStatus.STARTING
  ) {
    console.log(
      "Exiting early: action payload/status does not match LOCKING or STARTING."
    );
    return;
  }

  console.log("[collab] - gameSessionSaga - Initiating race condition...");

  const { stuck } = yield race({
    stuck: delay(10000),
    statusChanged: take((newAction: AnyAction) => {
      if (isSetStatusAction(newAction)) {
        console.log(
          "[collab] - gameSessionSaga - New action received during race:",
          newAction
        );
        return (
          newAction.payload !== GameSessionStatus.LOCKING &&
          newAction.payload !== GameSessionStatus.STARTING
        );
      }
      console.log(
        "[collab] - gameSessionSaga - Received non-setStatus action during race:",
        newAction
      );
      return false;
    }),
  });

  if (!stuck) {
    console.log(
      "[collab] - gameSessionSaga - Race condition: Status changed before 10 seconds."
    );
    return;
  } else {
    console.log(
      "[collab] - gameSessionSaga - Race condition: 10 seconds passed without status change."
    );
  }

  const isAdmin: boolean = yield select(
    (state: RootState) => state.session.isAdmin
  );

  if (!isAdmin) {
    console.log(
      "[collab] - gameSessionSaga - Exiting early: Current user is not an admin."
    );
    return;
  }

  const user: RootState["user"] = yield select(
    (state: RootState) => state.user
  );
  const { token } = user;
  const gameSessionId: string = yield select(
    (state: RootState) => state.gameSession.gameSessionId
  );
  const sessionId: string = yield select(
    (state: RootState) => state.session.sessionId
  );

  if (!token || !sessionId || !gameSessionId) {
    console.log(
      "Exiting early: Missing required values. Token:",
      token,
      "Session ID:",
      sessionId,
      "GameSession ID:",
      gameSessionId
    );
    return;
  }

  console.log(
    "[collab] - gameSessionSaga - Attempting to unlock game session on server..."
  );

  try {
    // yield put(gameSessionActions.setStatus(GameSessionStatus.LOCKING));
    yield call(changeGameSessionStatusServerside, {
      gameSessionId,
      sessionId,
      token,
      status: GameSessionStatus.UNSTARTED,
    });
    console.log(
      "[collab] - gameSessionSaga - Game session unlocked on server successfully."
    );
  } catch (error) {
    console.error("Error unlocking game session on server:", error);
    yield put(gameSessionActions.setStatus(GameSessionStatus.UNSTARTED));
    yield put(gameSessionActions.setInitiatedLock(false));
  }
}

function* broadcastGameSessionSet() {
  // send a message to the parent window to let it know that the session has been initialised with IS_SESSION_CONNECTED
  // set to true
  const targetOrigin = "*";
  const message = {
    messageType: PostMessageTypes.IS_GAME_SELECTED,
    payload: true,
  };
  // try {
  //   window.parent.postMessage(message, targetOrigin);
  // } catch (error) {
  //   console.error("Error posting message to parent window", error);
  // }
  try {
    yield call(postMessageToParent, message, targetOrigin);
  } catch (error) {
    console.error("Error posting message to parent window", error);
  }
}

/**
 * This saga is responsible for sending the breakout rooms to the parent window
 *
 * @param action
 */
function* broadcastBreakoutRoomsSet(action: AnyAction) {
  console.log("[collab] - gameSessionSaga - broadcastBreakoutRoomsSet", action);
  const rooms: string[][] = action.payload;
  // send a message to the parent window to let it know that the breakout rooms have been opened
  const targetOrigin = "*";
  const message = {
    messageType: PostMessageTypes.BREAKOUT_ROOMS_OPENED,
    payload: rooms,
  };
  try {
    yield call(postMessageToParent, message, targetOrigin);
  } catch (error) {
    console.error("Error posting message to parent window", error);
  }
}

function* broadcastBreakoutGroupSizeRequirements(action: AnyAction) {
  const { min, max } = action.payload;
  const targetOrigin = "*";
  const message = {
    messageType: PostMessageTypes.BREAKOUT_ROOM_SIZE_REQUIREMENTS,
    payload: {
      min,
      max,
    },
  };
  try {
    yield call(postMessageToParent, message, targetOrigin);
  } catch (error) {
    console.error("Error posting message to parent window", error);
  }
}

function* sendBreakoutRoomPlanInitialisationToServer(
  action: ReturnType<
    typeof gameSessionActions.serversideInitialiseBreakoutRoomPlan
  >
) {
  const LOG_PREFIX = "[sendBreakoutRoomPlanToServer]";

  console.log(`${LOG_PREFIX} started with action:`, action);

  const breakoutRoomPlan: string[][] = action.payload;
  console.log(`${LOG_PREFIX} Init Breakout Room Plan:`, breakoutRoomPlan);

  const isAdmin: boolean = yield select(
    (state: RootState) => state.session.isAdmin
  );
  console.log(`${LOG_PREFIX} Is user admin?`, isAdmin);
  if (!isAdmin) return;

  // check to see if the game session was locked
  const initiatedLock: boolean = yield select(
    (state: RootState) => state.gameSession.initiatedLock
  );
  const status: GameSessionStatus = yield select(
    (state: RootState) => state.gameSession.status
  );

  // Check to see that the game session is unlocked
  if (initiatedLock) return;

  // Check that the status of the game session is UNSTARTED
  if (status !== GameSessionStatus.UNSTARTED) return;

  // send the breakout rooms to the server
  const gameId: string = yield select(
    (state: RootState) => state.gameSession.gameId
  );

  const gameSessionId: string = yield select(
    (state: RootState) => state.gameSession.gameSessionId
  );

  const sessionId: string = yield select(
    (state: RootState) => state.session.sessionId
  );
  console.log(`${LOG_PREFIX} Session ID:`, sessionId);

  if (!sessionId || breakoutRoomPlan.length < 1 || !gameSessionId) {
    console.warn(
      `${LOG_PREFIX} Missing, session ID, breakout rooms, or game session ID`
    );
    return;
  }

  try {
    console.log(`${LOG_PREFIX} Sending breakout rooms to the server...`);
    yield call(initBreakoutRoomPlan, {
      gameSessionId,
      sessionId,
      breakoutRoomPlan,
    });
    console.log(`${LOG_PREFIX} Breakout rooms sent successfully!`);
  } catch (error) {
    console.error(
      `${LOG_PREFIX} Error sending breakout rooms to server:`,
      error
    );
  }
}

// this will check the payload to see if the game session has a breakout room plan that is not equal to [], if so
// it'll call broadcastBreakoutRoomsSet.
function* checkIfBreakoutRoomsSet(action: CombinedActions) {
  console.log("[collab] - gameSessionSaga - checkIfBreakoutRoomsSet", action);
  const breakoutRoomPlan: string[][] = action.payload.breakout_room_plan;
  const newAction = { payload: breakoutRoomPlan, type: action.type };
  if (breakoutRoomPlan.length > 0) {
    console.log(
      "[collab] - gameSessionSaga - checkIfBreakoutRoomsSet - breakout room plan is not empty"
    );
    yield call(broadcastBreakoutRoomsSet, newAction);
  }
}

// Helper function to wrap response.json()
async function parseJSON(response: Response): Promise<any> {
  return await response.json();
}

/**
 * This saga is responsible for sending a request to the server to add a player to the game session.
 * It is triggered when the joinPlayerToGame action is dispatched.
 * It will send a request to the server to add the player to the game session.
 * If the request is successful, the SSE channel will update the game session accordingly in a separate thread.
 * If the request is unsuccessful, the game session will not be updated.
 *
 * @param action
 * @returns
 */
function* addPlayerToGameServerside(
  action: CombinedActions
): Generator<CallEffect | SelectEffect, void, any> {
  const gameSessionId: string = yield select(
    (state: RootState) => state.gameSession.gameSessionId
  );

  const sessionId: string = yield select(
    (state: RootState) => state.session.sessionId
  );

  const publicUUID: string = yield select(
    (state: RootState) => state.user.publicUUID
  );

  if (!gameSessionId || !sessionId) return;

  // check if this player is already in the game session
  const players: string[] = yield select(
    (state: RootState) => state.gameSession.players
  );
  console.log({ players });

  const playerAlreadyInGame = players.includes(publicUUID);

  if (playerAlreadyInGame) {
    console.log(
      "Player already in game session. Exiting early.",
      "Game session ID:",
      gameSessionId,
      "Session ID:",
      sessionId,
      "Player ID:",
      publicUUID
    );
    return;
  }

  let headers = { "Content-Type": "application/json" };

  const requestURL = ENDPOINTS.gameSessions({
    sessionId,
    action: GameSessionActionEnum.PLAYERS,
    gameSessionId,
  });

  try {
    const response: Response = yield call(fetch, requestURL, {
      method: "PUT",
      headers,
      body: JSON.stringify({ action: GameSessionActionEnum.ADD }),
      credentials: "include",
    });

    if (!response.ok) {
      console.error("Failed to join the game.");
    }

    const data: any = yield call(parseJSON, response);

    // Dispatch another action to update the Redux store, if necessary.
    // For example:
    // yield put(updateGameSession(data));
  } catch (error: any) {
    if (error instanceof Error) {
      console.error("An error occurred:", error.message);
      // Dispatch an action to update the Redux store to indicate failure, if necessary.
      // For example:
      // yield put(failureJoinGame(error.message));
    } else {
      console.error("An unknown error occurred");
      // Handle other unknown cases
    }
  }
}

/**
 * This saga is responsible for sending a request to the server to add a player to the breakout room plan. It will
 * first check to see if the player is already in the breakout room plan - in a different breakout room. If they are,
 * it will send a request to the server to remove them from that room.
 * It will then send a request to the server to add them to the breakout room plan in the room specified in the action payload.
 * @param action
 */
function* addPlayerToBreakoutRoomPlanServerside(
  action: AnyAction
): Generator<CallEffect | SelectEffect, void, any> {
  console.log(
    "[collab] - gameSessionSaga - addPlayerToBreakoutRoomPlanServerside",
    action
  );

  const gameSessionId: string = yield select(
    (state: RootState) => state.gameSession.gameSessionId
  );

  const sessionId: string = yield select(
    (state: RootState) => state.session.sessionId
  );

  const publicUUID: string = yield select(
    (state: RootState) => state.user.publicUUID
  );

  if (!gameSessionId || !sessionId) return;

  // check if this player is already in the game session
  const breakoutRoomPlan: string[][] = yield select(
    (state: RootState) => state.gameSession.breakoutRoomPlan
  );
  console.log({ breakoutRoomPlan });

  if (breakoutRoomPlan.length < 1) {
    console.log(
      "[collab] - gameSessionSaga - Exiting early: breakout room plan is empty."
    );
    return;
  }

  let headers = { "Content-Type": "application/json" };

  const requestURL = ENDPOINTS.gameSessions({
    sessionId,
    action: GameSessionActionEnum.BREAKOUT_ROOM_PLAN,
    gameSessionId,
  });

  // Send request to server to add player to breakout room.
  // if the player is already in the breakout room plan, the server will remove them from the breakout room plan
  // and add them to the new breakout room
  // if the player is not already in the breakout room plan, the server will add them to the new breakout room
  // if the player is already in the breakout room plan and the new breakout room index is the same as the old
  // breakout room index, the server will do nothing
  try {
    let { breakoutRoomIndex } = action.payload;
    breakoutRoomIndex = parseInt(breakoutRoomIndex);

    const response: Response = yield call(fetch, requestURL, {
      method: "PUT",
      headers,
      body: JSON.stringify({
        player_uuid: publicUUID,
        action: BreakoutRoomPlanEnum.ADD,
        breakout_room_index: breakoutRoomIndex,
      }),
      credentials: "include",
    });

    if (!response.ok) {
      console.error("Failed to add player to breakout room plan.");
    }

    yield call(parseJSON, response);

    // Dispatch another action to update the Redux store, if necessary.
    // For example:
    // yield put(updateGameSession(data));
  } catch (error: any) {
    if (error instanceof Error) {
      console.error("An error occurred:", error.message);
      // Dispatch an action to update the Redux store to indicate failure, if necessary.
      // For example:
      // yield put(failureJoinGame(error.message));
    } else {
      console.error("An unknown error occurred");
      // Handle other unknown cases
    }
  }
}

export default function* gameSessionSaga() {
  yield all([
    takeLatest(
      gameSessionActions.serversideLockGameSession.type,
      lockGameSessionServerside
    ),
    takeEvery(gameSessionActions.setStatus.type, sendBreakoutRoomsToServer),
    takeLatest(gameSessionActions.setStatus.type, unlockGameSessionServerside),

    // Watching for both setStatus and setGameSession actions:
    takeLatest(
      [
        gameSessionActions.setStatus.type,
        gameSessionActions.setGameSession.type,
      ],
      unlockIfLongWait
    ),
    takeLatest(gameSessionActions.setGameSession.type, broadcastGameSessionSet),
    takeLatest(
      gameSessionActions.setBreakoutRoomPlan.type,
      broadcastBreakoutRoomsSet
    ),
    takeLatest(
      gameSessionActions.setGroupSize.type,
      broadcastBreakoutGroupSizeRequirements
    ),
    takeLatest(gameSessionActions.setGameSession.type, checkIfBreakoutRoomsSet),
    takeLatest(
      gameSessionActions.serversideInitialiseBreakoutRoomPlan.type,
      sendBreakoutRoomPlanInitialisationToServer
    ),
    takeLatest(
      gameSessionActions.joinPlayerToGame.type,
      addPlayerToGameServerside
    ),
    takeLatest(
      gameSessionActions.joinPlayerToBreakoutRoomPlan.type,
      addPlayerToBreakoutRoomPlanServerside
    ),
  ]);
}
