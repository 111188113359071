// GameSelection.tsx
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Game as GameType } from "../../types/index";
import { fetchGames } from "../../store/api/games";
import { useQuery } from "react-query";
import { useAuth } from "../../contexts/AuthContext";
import LoadingMessage from "../LoadingMessage/LoadingMessage";

interface GameCardProps {
  game: GameType;
  onSelect: (game: GameType) => void;
}

const GameCard: React.FC<GameCardProps> = ({ game, onSelect }) => {
  let breakoutRoom = `Breakout rooms of: ${game.group_size_min} - ${game.group_size_max} people`;
  if (game.group_size_max === 1) {
    breakoutRoom = "A group game - but people participate as individuals";
  }
  return (
    <div className="col" key={`${game.id}-display`}>
      <div className="card h-100">
        <img src={game.image_url} className="card-img-top" alt={game.name} />
        <div className="card-body">
          <h5 className="card-title">{game.description}</h5>
          <p className="card-text pt-3">Player limit: Unlimited</p>
          <p className="card-text">{breakoutRoom}</p>
          <p className="card-text">
            Takes around: {game.time_requirement} minute
            {game.time_requirement > 1 ? "s" : ""}
          </p>
          <Button onClick={() => onSelect(game)} variant="outline-success">
            Select Game
          </Button>
        </div>
      </div>
    </div>
  );
};

interface GameSelectionProps {
  setSelectedGame: (game: GameType) => void;
  gameLength: number | null;
  breakoutRoomSize: string | null;
}

const GameSelection: React.FC<GameSelectionProps> = ({
  setSelectedGame,
  gameLength,
  breakoutRoomSize,
}) => {
  const { token } = useAuth();
  const {
    data: games,
    isLoading,
    isError,
  } = useQuery<GameType[], Error>(
    ["games", gameLength, breakoutRoomSize],
    () => fetchGames({ gameLength, breakoutRoomSize, token }),
    {
      staleTime: 1,
      refetchOnWindowFocus: false, // We don't want to refetch when the user switches tabs
      // dont cache the games
      cacheTime: 0,
    }
  );

  if (isLoading) {
    return <LoadingMessage message={"Loading games"} />;
  } else {
    if (isError) {
      return <div className="text-danger">Error fetching games.</div>;
    }
  }

  if (!games) {
    return null;
  }

  if (games.length === 0) {
    return <div className="text-warning">No games found.</div>;
  }

  if (games.length > 0) {
    return (
      <Row xs={1} md={2} lg={3} className="g-4 justify-content-md-center">
        {games.map((game) => (
          <GameCard key={game.id} game={game} onSelect={setSelectedGame} />
        ))}
      </Row>
    );
  }

  return null;
};

export default GameSelection;
