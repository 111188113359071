// slices/gameSlice.ts
import { createSlice } from "@reduxjs/toolkit";
import { BreakoutRoomStatus } from "../../hooks/types";

export interface BreakoutRoomState {
  peers: string[];
  gameUrl: string;
  status: BreakoutRoomStatus;
  id: string;
}

const breakoutRoomSlice = createSlice({
  name: "breakoutRoom",
  initialState: {
    peers: [],
    gameUrl: "",
    status: BreakoutRoomStatus.UNSTARTED,
    id: "",
  } as BreakoutRoomState,
  reducers: {
    resetBreakoutRoom: (state, action) => {
      state.peers = [];
      state.gameUrl = "";
      state.status = BreakoutRoomStatus.UNSTARTED;
      state.id = "";
    },
    setBreakoutRoom: (state, action) => {
      return action.payload;
    },
    setPeers: (state, action) => {
      return action.payload;
    },
    setGameUrl: (state, action) => {
      state.gameUrl = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    requestStatusChange: (state, action) => {},
  },
});

export const { actions: breakoutRoomActions, reducer: breakoutRoomReducer } =
  breakoutRoomSlice;
