import { useSelector } from "react-redux";
import { RootState } from "../../store";

export const BreakoutRoomSlot = ({
  title,
  isWarning,
  isError,
}: {
  title: string;
  isWarning?: boolean;
  isError?: boolean;
}) => {
  let classIndicator = "";
  if (isWarning) {
    classIndicator += " text-warning";
  }
  if (isError) {
    classIndicator += " text-danger";
  }
  return <li className={`${classIndicator}`}>{title}</li>;
};

const RequiredSlots = ({
  players,
  minPlayers,
}: {
  players: string[];
  minPlayers: number;
}) => {
  // empty slots must be at least 0
  const emptySlots = Math.max(minPlayers - players.length, 0);
  const emptySlotsArray = Array(emptySlots).fill(null);
  // show an empty slot div for each empty slot
  for (let i = 0; i < emptySlots; i++) {
    emptySlotsArray.push(
      <BreakoutRoomSlot
        key={`empty_${i}`}
        title={"Player required"}
        isError={true}
      />
    );
  }
  return <>{emptySlotsArray}</>;
};

const FilledSlots = ({
  players,
  maxPlayers,
}: {
  players: string[];
  maxPlayers: number;
}) => {
  const visitors = useSelector((state: RootState) => state.visitors);
  const filledSlotsArray = Array(players.length).fill(null);
  // show an empty slot div for each empty slot
  for (let i = 0; i < players.length; i++) {
    const visitor = visitors[players[i]];
    const isError = maxPlayers < i + 1;
    filledSlotsArray.push(
      <BreakoutRoomSlot
        key={players[i]}
        title={`${(visitor && (visitor.name || visitor.sid)) || players[i]}`}
        isError={isError}
      />
    );
  }
  return <>{filledSlotsArray}</>;
};

interface BreakoutRoomProps {
  roomName: string;
  players: string[];
  maxPlayers: number;
  minPlayers: number;
  removeRoom: () => void;
  showRoomControls: boolean;
}

function BreakoutRoom({
  roomName,
  players,
  maxPlayers,
  minPlayers,
  removeRoom,
  showRoomControls,
}: BreakoutRoomProps) {
  return (
    <div className="col">
      <div className="card h-100">
        <div className="card-header">
          <h5 className="card-title">{roomName}</h5>
        </div>
        <div className="card-body">
          <ol>
            <FilledSlots players={players} maxPlayers={maxPlayers} />
            <RequiredSlots players={players} minPlayers={minPlayers} />
          </ol>
          {showRoomControls && !players.length && (
            <button onClick={removeRoom}>Remove Room</button>
          )}
        </div>
      </div>
    </div>
  );
}

export default BreakoutRoom;
