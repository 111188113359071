// useLocalStorage.ts

import { useState } from "react";

function useLocalStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      // If item is a string that doesn't start and end with curly braces or square brackets, return it as is
      if (item && !item.match(/^({.*}|[.*])$/)) return item as any as T;
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      // If valueToStore is a string, store it as is, otherwise stringify it
      const valueForStorage =
        typeof valueToStore === "string"
          ? valueToStore
          : JSON.stringify(valueToStore);
      window.localStorage.setItem(key, valueForStorage);
    } catch (error) {
      console.error(`Failed to set ${key} in localStorage:`, error);
    }
  };

  return [storedValue, setValue] as const;
}

export default useLocalStorage;
