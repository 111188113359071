import React from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { createSession } from "../../store/api/sessions";
import "./styles.module.scss";
import Layout from "../../components/MarketingPageLayout/MarketingPageLayout";
import { appName } from "../../utils/constants";
import { useAuth } from "../../contexts/AuthContext";
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import { useDispatch } from "react-redux";
import { sessionActions } from "../../store/slices";

const Error: React.FC<{ error: Error | unknown }> = ({ error }) => {
  return (
    <div className="text-center">
      <h3> An error occurred while creating the session.</h3>
      <br />
      <p className="lead text-danger">{(error as Error).message}</p>
    </div>
  );
};

const CreatingSession: React.FC = () => {
  const { token, isLoading } = useAuth();

  const navigate = useNavigate();

  const { mutate, isError, error } = useMutation(
    () => createSession({ token }),
    {
      onError: (error) => {
        console.error(error);
      },
      onSuccess: (resp) => {
        const { id } = resp.session;

        setTimeout(() => {
          navigate(`/sessions/${id}`);
        }, 1000);
      },
    }
  );

  const [dotIndex, setDotIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setDotIndex((prevIndex) => (prevIndex + 1) % 4);
    }, 500);

    if (!isLoading && token) {
      mutate();
    }

    return () => clearInterval(intervalId);
  }, [isLoading, token, mutate]);

  return (
    <Layout
      title={`New ${appName} session`}
      description={`${appName} by Chinwag`}
    >
      <div className="d-flex justify-content-center align-items-center vh-100 flex-column">
        {!isError && <LoadingMessage message={"Creating new session"} />}
        {isError && <Error error={error} />}
      </div>
    </Layout>
  );
};

export default CreatingSession;
