import { takeEvery, call, all, select } from "redux-saga/effects";
import { visitorsActions } from "../slices/visitorsSlice";
import { RootState } from "../index";
import { userActions } from "../slices";
import {
  updateIsAdminOnServer,
  updatePublicMetadataOnServer,
} from "../api/people";
import { PostMessageTypes } from "../../types";

// check the payload of the action to see if the sid matches the state.user.publicUUID. If so,
// check if the name in the payload matches the state.user.name. If not, dispatch an action to
// update the user name on the serverside.
function* checkUserNameInSync(
  action: ReturnType<typeof visitorsActions.addVisitor>
) {
  const { name, sid } = action.payload;

  const user: RootState["user"] = yield select(
    (state: RootState) => state.user
  );
  const { name: localName, publicUUID } = user;

  if (sid !== publicUUID) return;

  const sessionId: string = yield select(
    (state: RootState) => state.session.sessionId
  );

  if (!publicUUID || !localName || !sessionId) return;

  if (localName !== name) {
    try {
      yield call(updatePublicMetadataOnServer, {
        uuid: publicUUID,
        key: "name",
        value: localName,
        sessionId,
      });
    } catch (error) {
      console.error("Error updating name on server:", error);
    }
  }
}

// checks the payload of the setName action to see if it matches the name value in state.session.visitors[sid].name.
// If not, dispatch an action to update the user name on the serverside.
function* ensureNameSyncedWithServer(
  action: ReturnType<typeof userActions.setName>
) {
  console.log("ensureNameSyncedWithServer", action);
  const name = action.payload;
  const user: RootState["user"] = yield select(
    (state: RootState) => state.user
  );
  const { publicUUID } = user;
  console.log({ publicUUID });
  const visitors: RootState["visitors"] = yield select(
    (state: RootState) => state.visitors
  );
  console.log({ visitors });
  const sessionId: string = yield select(
    (state: RootState) => state.session.sessionId
  );
  console.log({ sessionId });
  if (!publicUUID || !name || !sessionId) return;

  if (visitors[publicUUID].name !== name) {
    try {
      yield call(updatePublicMetadataOnServer, {
        uuid: publicUUID,
        key: "name",
        value: name,
        sessionId,
      });
      // Here, you can also dispatch a success action if necessary
    } catch (error) {
      // Handle error, maybe dispatch an error action
      console.error("Error updating name on server:", error);
    }
  }
}

// Sends any updated public metadata to the the server
function* broadcastPublicMetadataChange(
  action: ReturnType<typeof userActions.setPublicMetadata>
) {
  console.log("broadcastPublicMetadataChange", action);
  const { key, value } = action.payload;

  if (!key || !value) {
    console.error(
      "broadcastPublicMetadataChange: No publicMetadata to broadcast"
    );
    return;
  }

  const user: RootState["user"] = yield select(
    (state: RootState) => state.user
  );
  const { publicUUID } = user;
  console.log({ publicUUID });
  const sessionId: string = yield select(
    (state: RootState) => state.session.sessionId
  );
  console.log({ sessionId });
  if (!publicUUID || !sessionId) return;

  try {
    yield call(updatePublicMetadataOnServer, {
      uuid: publicUUID,
      key: key,
      value: value,
      sessionId,
    });
    // Here, you can also dispatch a success action if necessary
  } catch (error) {
    // Handle error, maybe dispatch an error action
    console.error("Error updating public metadata on server:", error);
  }
}

// Sends the isAdmin change to serverside
function* broadcastIsAdminChange(
  action: ReturnType<typeof userActions.setIsAdmin>
) {
  console.log("broadcastIsAdminChange", action);
  const isAdmin = action.payload;

  if (isAdmin === undefined) {
    console.error("broadcastIsAdminChange: No isAdmin to broadcast");
    return;
  }

  const user: RootState["user"] = yield select(
    (state: RootState) => state.user
  );
  const { publicUUID } = user;

  const sessionId: string = yield select(
    (state: RootState) => state.session.sessionId
  );
  console.log({ sessionId });
  if (!publicUUID || !sessionId) return;

  try {
    yield call(updateIsAdminOnServer, {
      uuid: publicUUID,
      isAdmin,
      sessionId,
    });
    // Here, you can also dispatch a success action if necessary
  } catch (error) {
    // Handle error, maybe dispatch an error action
    console.error("Error updating isAdmin on server:", error);
  }
}

// Sends the publicUUID to the parent window
function* broadcastPublicUUID(
  action: ReturnType<typeof userActions.setPublicUUID>
) {
  console.log("broadcastPublicUUID", action);
  const publicUUID = action.payload;

  if (!publicUUID) {
    console.error("broadcastPublicUUID: No publicUUID to broadcast");
    return;
  }

  const targetOrigin = "*";
  const message = {
    messageType: PostMessageTypes.COLLAB_USER_ID_ASSIGNED,
    payload: publicUUID,
  };

  window.parent.postMessage(message, targetOrigin);
}

export default function* userSaga() {
  yield all([
    takeEvery(visitorsActions.addVisitor.type, checkUserNameInSync),
    takeEvery(visitorsActions.updateVisitor.type, checkUserNameInSync),
    takeEvery(userActions.setName.type, ensureNameSyncedWithServer),
    takeEvery(userActions.setPublicUUID.type, broadcastPublicUUID),
    takeEvery(
      userActions.setPublicMetadata.type,
      broadcastPublicMetadataChange
    ),
    takeEvery(userActions.setIsAdmin.type, broadcastIsAdminChange),
  ]);
}
