// NameModal.tsx

import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import useLocalStorage from "../../hooks/useLocalStorage";
import { localStorageEnum } from "../../utils/constants";

interface Props {
  show: boolean;
  onClose: () => void;
}

const NameModal: React.FC<Props> = ({ show, onClose }) => {
  const [username, setUsername] = useLocalStorage<string | null>(
    localStorageEnum.USERNAME,
    null
  );
  const [nameIsValid, setNameIsValid] = useState<boolean>(!!username);
  const [localName, setLocalName] = useState<string>(username || "");

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("Name changed");
    const name = e.target.value;
    setNameIsValid(name.length > 0);
    setLocalName(name);
  };

  const handleNameSubmit = (e: React.FormEvent) => {
    console.log("Name form submitted");
    e.preventDefault(); // prevent the default form submission behavior
    if (!nameIsValid) {
      console.log("Name is not valid");
      return;
    }
    console.log("Name is valid");
    setUsername(localName);
    onClose();
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={!!username}
    >
      <Modal.Header closeButton={!!username}>
        <Modal.Title>Enter your name</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={handleNameSubmit}>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={localName}
              onChange={handleNameChange}
              autoFocus={true}
            />
          </Form.Group>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleNameSubmit}
              type="submit"
              disabled={!nameIsValid}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default NameModal;
