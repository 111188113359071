import { takeEvery, all, select, call } from "redux-saga/effects";

import { breakoutRoomActions } from "../slices";
import { BreakoutRoomStatus } from "../../hooks/types";
import { ReqHeaders } from "../api/auth";
import {
  BreakoutRoomActionsEnum,
  ENDPOINTS,
  GameSessionActionEnum,
} from "../api/urls";
import { RootState } from "..";
import { AnyAction } from "@reduxjs/toolkit";
import { BreakoutRoomState } from "../slices/breakoutRoomSlice";
import { PostMessageTypes } from "../../types";
import { postMessageToParent } from "../../utils";

async function changeBreakoutRoomStatusServerside({
  breakoutRoomId,
  sessionId,
  gameSessionId,
  status,
}: {
  breakoutRoomId: string;
  sessionId: string;
  gameSessionId: string;
  status: BreakoutRoomStatus;
}) {
  let headers: ReqHeaders = { "Content-Type": "application/json" };
  // get the session id from state

  const response = await fetch(
    ENDPOINTS.breakoutRooms({
      breakoutRoomId,
      sessionId,
      gameSessionId,

      action: BreakoutRoomActionsEnum.STATUS,
    }),
    {
      method: "PUT",
      headers,
      body: JSON.stringify({ status }),
      credentials: "include",
    }
  );

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(
      errorData.message ||
        "Error while trying to change the breakout room status"
    );
  }
  return response.json();
}

/*
 * Description.
 * This saga will send the request to the server to change the status of the local breakout room. If the request is successful,
 * the SSE channel will update the breakout room status accordingly in a separate thread.
 */
function* requestStatusChange(
  action: ReturnType<typeof breakoutRoomActions.requestStatusChange>
) {
  try {
    const requestedStatus = action.payload;
    const { id: breakoutRoomId, status } = yield select(
      (state: RootState) => state.breakoutRoom
    );
    const gameSessionId: string = yield select(
      (state: RootState) => state.gameSession.gameSessionId
    );
    const sessionId: string = yield select(
      (state: RootState) => state.session.sessionId
    );
    if (requestedStatus === status) {
      throw new Error(
        "The requested breakout room status is the same as the current status."
      );
    }

    yield call(changeBreakoutRoomStatusServerside, {
      breakoutRoomId,
      sessionId,
      gameSessionId,
      status: requestedStatus,
    });
  } catch (error) {
    console.log(error);
  }
}

/**
 * This saga is responsible for sending a message to the parent window to let it know that the breakout room status has
 * changed.
 * @param action
 */
function* broadcastBreakoutRoomStatusChange(action: AnyAction) {
  console.log("broadcastBreakoutRoomStatusChange", action);
  const status: BreakoutRoomStatus = action.payload.status;
  // send a message to the parent window to let it know that the breakout rooms status has changed
  const targetOrigin = "*";
  const message = {
    messageType: PostMessageTypes.BREAKOUT_ROOM_STATUS_CHANGED,
    payload: status,
  };
  try {
    yield call(postMessageToParent, message, targetOrigin);
  } catch (error) {
    console.error("Error posting message to parent window", error);
  }
}

export default function* gameSessionSaga() {
  yield all([
    takeEvery(
      breakoutRoomActions.requestStatusChange.type,
      requestStatusChange
    ),
    takeEvery(
      breakoutRoomActions.setBreakoutRoom.type,
      broadcastBreakoutRoomStatusChange
    ),
  ]);
}
