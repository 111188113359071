import React, { ReactNode, useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import { appName } from "../../utils/constants";

// allow react-helmet to be used in this component
interface LayoutProps {
  children: React.ReactNode;
  showNavbar?: boolean; // Optional flag to show or hide Navbar
  showFooter?: boolean; // Optional flag to show or hide Footer
  title: string;
  description: string;
  keywords?: string[];
  twitterCard?: string;
  twitterSite?: string;
  twitterUrl?: string;
  twitterTitle?: string;
  twitterDescription?: string;
  twitterImage?: string;
  ogType?: string;
  ogUrl?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  showNavbar = true,
  showFooter = true,
  title,
  description,
  keywords = [],
  twitterCard = "summary_large_image",
  twitterSite = "@getchinwag",
  // get the current url
  twitterUrl = window.location.href,
  twitterTitle = appName,
  twitterDescription = "Inject more joy into your remote team bonding.",
  twitterImage = "https://team-bonding.vercel.app/images/hero.png",
  ogType = "website",
  ogUrl = window.location.href,
  ogTitle = appName,
  ogDescription = "Inject more joy into your remote team bonding.",
  ogImage = "https://team-bonding.vercel.app/images/hero.png",
}) => {
  return (
    <div className="container-fluid g-0">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords.join(", ")} />

        <meta name="twitter:card" content={twitterCard} />
        <meta name="twitter:site" content={twitterSite} />
        <meta name="twitter:url" content={twitterUrl} />
        <meta name="twitter:title" content={twitterTitle} />
        <meta name="twitter:description" content={twitterDescription} />
        <meta name="twitter:image" content={twitterImage} />

        <meta property="og:type" content={ogType} />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:title" content={ogTitle} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:image" content={ogImage} />
      </Helmet>

      {showNavbar && <Navbar />}
      {children}
      {showFooter && <Footer />}
    </div>
  );
};

export default Layout;
