import { ReqHeaders } from "./auth";
import { ENDPOINTS } from "./urls";

// The fetch function
async function fetchGames({
  breakoutRoomSize,
  gameLength,
  token,
}: {
  breakoutRoomSize: string | null;
  gameLength: number | null;
  token: string | null;
}) {
  const url = `${ENDPOINTS.games()}?breakoutRoomSize=${breakoutRoomSize}&time_requirement=${gameLength}`;
  let headers: ReqHeaders = {
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    method: "GET",
    headers,
    credentials: "include",
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
}

async function sendGameSelection({
  sessionId,
  gameId,
  token,
}: {
  sessionId: string;
  gameId: string;
  token: string;
}) {
  let headers: ReqHeaders = { "Content-Type": "application/json" };

  const response = await fetch(ENDPOINTS.gameSessions({ sessionId }), {
    method: "POST",
    headers,
    body: JSON.stringify({ game_id: gameId }),
    credentials: "include",
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Error while creating game session");
  }
  return response.json();
}

export { fetchGames, sendGameSelection };
