import { GameSessionStatus } from "../../hooks/types";
import { ReqHeaders } from "./auth";
import { ENDPOINTS, GameSessionActionEnum } from "./urls";

async function changeGameSessionStatusServerside({
  gameSessionId,
  sessionId,
  token,
  status,
}: {
  gameSessionId: string;
  sessionId: string;
  token: string;
  status: GameSessionStatus;
}) {
  let headers: ReqHeaders = { "Content-Type": "application/json" };

  const response = await fetch(
    ENDPOINTS.gameSessions({
      sessionId: sessionId,
      gameSessionId,
      action: GameSessionActionEnum.STATUS,
    }),
    {
      method: "PUT",
      headers,
      body: JSON.stringify({ status }),
      credentials: "include",
    }
  );

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(
      errorData.message || "Error while changing the game session status"
    );
  }
  return response.json();
}

async function setupBreakoutRooms({
  gameSessionId,
  sessionId,
  breakoutRoomPlan,
  gameId,
}: {
  gameSessionId: string;
  sessionId: string;
  breakoutRoomPlan: string[][];
  gameId: string;
}) {
  let headers: ReqHeaders = { "Content-Type": "application/json" };

  const payload = {
    breakout_rooms: breakoutRoomPlan.map((room) => ({ players: room })),
    game_id: gameId,
  };

  const response = await fetch(
    ENDPOINTS.gameSessions({
      sessionId: sessionId,
      gameSessionId,
      action: GameSessionActionEnum.BREAKOUT_ROOMS,
    }),
    {
      method: "POST",
      headers,
      body: JSON.stringify(payload),
      credentials: "include",
    }
  );

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(
      errorData.message || "Error while setting up breakout rooms"
    );
  }
  return response.json();
}

async function initBreakoutRoomPlan({
  gameSessionId,
  sessionId,
  breakoutRoomPlan,
}: {
  gameSessionId: string;
  sessionId: string;
  breakoutRoomPlan: string[][];
}) {
  let headers: ReqHeaders = { "Content-Type": "application/json" };

  const payload = {
    breakout_rooms: breakoutRoomPlan,
  };

  const response = await fetch(
    ENDPOINTS.gameSessions({
      sessionId: sessionId,
      gameSessionId,
      action: GameSessionActionEnum.BREAKOUT_ROOM_PLAN,
    }),
    {
      method: "POST",
      headers,
      body: JSON.stringify(payload),
      credentials: "include",
    }
  );

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(
      errorData.message || "Error while initialising breakout rooms"
    );
  }
  return response.json();
}

async function updateBreakoutRoomPlan({
  gameSessionId,
  sessionId,
  breakoutRoomIndex,
  uuid,
  update,
}: {
  gameSessionId: string;
  sessionId: string;
  breakoutRoomIndex: number;
  gameId: string;
  uuid: string;
  update: "add" | "remove";
}) {
  let headers: ReqHeaders = { "Content-Type": "application/json" };

  const payload = {
    breakout_room_index: breakoutRoomIndex,
    uuid,
    update,
  };

  const response = await fetch(
    ENDPOINTS.gameSessions({
      sessionId: sessionId,
      gameSessionId,
      action: GameSessionActionEnum.BREAKOUT_ROOM_PLAN,
    }),
    {
      method: "PUT",
      headers,
      body: JSON.stringify(payload),
      credentials: "include",
    }
  );

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Error while updating breakout rooms");
  }
  return response.json();
}

export {
  changeGameSessionStatusServerside,
  setupBreakoutRooms,
  initBreakoutRoomPlan,
  updateBreakoutRoomPlan,
};
