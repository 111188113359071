import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Modal } from "react-bootstrap";

import GameChoices from "./GameChoices";
import SelectGameLength from "./SelectGameLength";

import { Game as GameType } from "../../types/index";
import SelectBreakoutRoomSize from "./SelectBreakoutRoomSize";
import GameSessionSteps, { NewGameStepsEnum } from "./NewGameSessionSteps";

import LoadingMessage from "../LoadingMessage/LoadingMessage";

import { sendGameSelection } from "../../store/api/games";

import { useAuth } from "../../contexts/AuthContext";
import { useParams } from "react-router-dom";
import SuccessMessage from "../SuccessMessage/SuccessMessage";
import NavigationCountdownProgressBar from "../NavigationCountdownProgressBar/NavigationCountdownProgressBar";

const NewGameSession: React.FC<{
  show: boolean;
  onClose: () => void;
}> = ({ show, onClose }) => {
  const [gameLength, setGameLength] = useState(null as number | null);
  const [breakoutRoomSize, setBreakoutRoomSize] = useState(
    null as string | null
  );
  const [selectedGame, setSelectedGame] = useState<GameType | null>(null);
  const [currentStep, setCurrentStep] = useState(NewGameStepsEnum.GAME_LENGTH);
  const [error, setError] = useState<string | null>(null);
  const [gameCreatedSuccess, setGameCreatedSuccess] = useState<boolean>(false);

  const { token } = useAuth();
  const { session_id: sessionId } = useParams();
  const waitTime = 3000;
  const mutation = useMutation(sendGameSelection, {
    onSuccess: () => {
      // Display success message
      setGameCreatedSuccess(true);
    },
    onError: (error: Error) => {
      // Handle error (e.g., show an error message to the user)
      setError(`Error creating game session: ${error.message}`);
    },
  });

  // Reset the currentStep when the modal is shown
  useEffect(() => {
    if (show) {
      setError(null);
      setGameCreatedSuccess(false);
      setCurrentStep(NewGameStepsEnum.GAME_LENGTH);
      setGameLength(null);
      setBreakoutRoomSize(null);
      setSelectedGame(null);
    }
  }, [show]);

  // when the currentStep is changed, we should reset the step changed to, and the steps after it
  useEffect(() => {
    if (currentStep === NewGameStepsEnum.GAME_LENGTH) {
      setGameLength(null);
      setBreakoutRoomSize(null);
      setSelectedGame(null);
    } else if (currentStep === NewGameStepsEnum.GROUP_SIZE) {
      setSelectedGame(null);
      setBreakoutRoomSize(null);
    } else if (currentStep === NewGameStepsEnum.GAME_CHOICES) {
      setSelectedGame(null);
    }
    setError(null); // if the step is changing we should clear the error
  }, [currentStep]);

  // when game length is set advance to group size
  useEffect(() => {
    if (gameLength) {
      setCurrentStep(NewGameStepsEnum.GROUP_SIZE);
      // deselect game
      setSelectedGame(null);
    }
  }, [gameLength]);

  // when group size is set advance to game selection
  useEffect(() => {
    if (breakoutRoomSize) {
      setCurrentStep(NewGameStepsEnum.GAME_CHOICES);
      // deselect game
      setSelectedGame(null);
    }
  }, [breakoutRoomSize]);

  // when game is selected advance to creating game session
  useEffect(() => {
    if (selectedGame) {
      setCurrentStep(NewGameStepsEnum.CREATING_GAME_SESSION);
    }
  }, [selectedGame]);

  // When game is selected, trigger the mutation
  useEffect(() => {
    if (selectedGame && token && sessionId) {
      // Show loading message while sending request
      setCurrentStep(NewGameStepsEnum.CREATING_GAME_SESSION);

      mutation.mutate({ sessionId, gameId: selectedGame.id, token });
    }
  }, [selectedGame]);

  useEffect(() => {
    console.log(
      "[collab] - NewGameSession - useEffect - window.self !== window.top",
      window.self !== window.top
    );
    if (window.self !== window.top) {
      // Check if in an iframe
      const modalElement = document.querySelector(".modal");
      if (modalElement instanceof HTMLElement) {
        // Type guard
        modalElement.style.position = "absolute";
        modalElement.style.height = `${window.outerHeight}px`;
      }
    }
  }, [show]);

  return (
    <Modal show={show} onHide={onClose} fullscreen={true} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <GameSessionSteps
            currentStep={currentStep}
            onStepChange={setCurrentStep}
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentStep === NewGameStepsEnum.GAME_LENGTH && (
          <SelectGameLength onSelect={setGameLength} />
        )}
        {currentStep === NewGameStepsEnum.GROUP_SIZE && gameLength && (
          <SelectBreakoutRoomSize
            onSelect={setBreakoutRoomSize}
            gameLength={gameLength}
          />
        )}
        {currentStep === NewGameStepsEnum.GAME_CHOICES && (
          <GameChoices
            setSelectedGame={setSelectedGame}
            gameLength={gameLength}
            breakoutRoomSize={breakoutRoomSize}
          />
        )}
        {currentStep === NewGameStepsEnum.CREATING_GAME_SESSION &&
          !error &&
          !gameCreatedSuccess && (
            <LoadingMessage message={"Creating Game Session"} />
          )}
        {error && <div style={{ color: "red" }}>{error}</div>}
        {gameCreatedSuccess && (
          <div>
            <div className="row">
              <div className="col">
                <SuccessMessage message={"Game Session Created Successfully"} />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <NavigationCountdownProgressBar
                  duration={waitTime}
                  onComplete={onClose}
                  message={`Closing modal in ${waitTime / 1000} seconds.`}
                />
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default NewGameSession;
