export type ConnectedCallback = (data: { remoteSID: string }) => void;
export type DisconnectedCallback = (data: { remoteSID: string }) => void;
export type InitCallback = (data: { peers: string[] }) => void;
export type SessionAdminsCallback = (data: {
  session_admins: string[];
}) => void;
export type GameSessionCallback = (data: {
  game_session: GameSessionType;
}) => void;
export type GameSessionPlayersUpdateCallback = (
  data: GameSessionPlayersUpdate
) => void;

// Enum for the different status of a game session
export enum GameSessionStatus {
  UNSTARTED = "unstarted",
  LOCKING = "locking", // this is only used on the client side by the admin that actually is going to send the breakout rooms serverside
  STARTING = "starting",
  STARTED = "started",
  ENDED = "ended",
}

// Enum for the different status of a breakout room
export enum BreakoutRoomStatus {
  UNSTARTED = "unstarted",
  STARTED = "started",
  ENDED = "ended",
}

export interface GameSessionType {
  gameId: string;
  gameSessionId: string;
  players: string[];
  status: GameSessionStatus;
  groupSizeMin: number;
  groupSizeMax: number;
}

export interface GameSessionPlayersUpdate {
  update_type: "add" | "remove";
  sid: string;
}

export enum BreakoutRoomCreationModes {
  // this is the default mode where the breakout rooms are created by collab as people join the game session
  AUTOMATIC_ASSIGNMENT = "automatic-assignment",
  // this is where the breakout room quantity is set by the admin but players have to manually select their breakout room
  SELF_SELECTION = "self-selection",
}
