import React, { useState, CSSProperties, useEffect } from "react";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { GameSessionStatus } from "../../hooks/types";
import { getVerticalScrollbarWidth } from "../../utils";

const EmbeddedGame: React.FC = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const { status } = useSelector((state: RootState) => state.gameSession);
  const { gameUrl } = useSelector((state: RootState) => state.breakoutRoom);
  const [height, setHeight] = useState<number | null>(null);

  // when the game status changes to "started", and gameUrl is not null, then we want to show the game
  // in full screen mode
  useEffect(() => {
    if (status === GameSessionStatus.STARTED && gameUrl) {
      setIsFullScreen(true);
    } else {
      setIsFullScreen(false);
    }
  }, [status, gameUrl]);

  useEffect(() => {
    let debounce: NodeJS.Timeout | number; // Moved inside useEffect

    const resizeObserver = new ResizeObserver(() => {
      // Debounce sending of height updates
      clearTimeout(debounce);
      debounce = setTimeout(() => {
        let newHeight = window.outerHeight;
        // newHeight += Math.abs(getVerticalScrollbarWidth());

        if (newHeight === height) {
          return;
        }

        setHeight(newHeight);
      }, 100);
    });

    resizeObserver.observe(document.body);

    return () => {
      console.log("[collab] - AppRouter - cleaning up");
      clearTimeout(debounce);
      resizeObserver.disconnect();
    };
  }, []);

  const containerStyle: CSSProperties = isFullScreen
    ? {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1000,
      }
    : {
        width: "200px",
        height: "200px",
      };

  return (
    <div style={containerStyle}>
      <iframe
        src={gameUrl}
        width="100%"
        height={`${height}px`}
        title="game"
        sandbox="allow-same-origin allow-scripts allow-modals allow-popups allow-forms"
        style={{ border: "none", zIndex: 1 }}
      >
        Your browser does not support iframes.
      </iframe>
    </div>
  );
};

export default EmbeddedGame;
