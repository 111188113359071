// AppRouter.tsx

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NewSession from "./pages/NewSessionPage/NewSessionPage";
import Session from "./pages/SessionPage/SessionPage";
import VisitorView from "./components/VisitorView/VisitorView";
import AdminView from "./components/AdminView/AdminView";
import NotFound from "./pages/NotFoundPage/NotFoundPage";
import NewGameSession from "./components/NewGameSession/NewGameSession";
import HomePage from "./pages/HomePage/HomePage";
import AboutPage from "./pages/AboutPage/AboutPage";
import LandingPage from "./pages/LandingPage/LandingPage";
import { useEffect, useState } from "react";
import { PostMessageTypes } from "./types";
import TermsPage from "./pages/TermsPage/TermsPage";
import PrivacyPage from "./pages/PrivacyPage/PrivacyPage";
import WebexPage from "./pages/WebexPage/WebexPage";

/**
 * This function checks if the vertical scrollbar is present on the page.
 * @returns
 */
const isVerticalScrollbarPresent = () => {
  const beforeScrollbarHidden = document.body.clientHeight;
  const overflowState = document.body?.style.overflow;

  document.body.style.overflow = "hidden"; // hide the scrollbar
  const afterScrollbarHidden = document.body.clientHeight;
  document.body.style.overflow = overflowState; // restore the overflow state

  console.log("[collab] - isVerticalScrollbarPresent", {
    beforeScrollbarHidden,
    afterScrollbarHidden,
    overflowState,
  });

  return beforeScrollbarHidden !== afterScrollbarHidden;
};

const AppRouter: React.FC = () => {
  // if we're on localhost - show the HomePage for "/" otherwise show the LandingPage
  const isLocalhost =
    ["localhost", "127.0.0.1"].indexOf(window.location.hostname) > -1;
  const [showHomePage, setShowHomePage] = useState(isLocalhost);

  useEffect(() => {
    if (isLocalhost) {
      setShowHomePage(true);
    } else {
      setShowHomePage(false);
    }
  }, [isLocalhost]);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          // element={showHomePage ? <HomePage /> : <LandingPage />}
          element={<HomePage />}
        />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/webex" element={<WebexPage />} />
        <Route path="/legal/terms" element={<TermsPage />} />
        <Route path="/legal/privacy" element={<PrivacyPage />} />
        <Route path="/new-session" element={<NewSession />} />
        <Route path="/sessions/:session_id" element={<Session />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
