// slices/gameSlice.ts
import { createSlice } from "@reduxjs/toolkit";
import {
  GameSessionType,
  GameSessionStatus,
  BreakoutRoomCreationModes,
} from "../../hooks/types";

export interface GameSessionState extends GameSessionType {
  breakoutRoomPlan: string[][];
  initiatedLock: boolean;
  breakoutRoomCreationMode: BreakoutRoomCreationModes;
}

const gameSessionSlice = createSlice({
  name: "gameSession",
  initialState: {
    gameId: "",
    gameSessionId: "",
    players: [],
    status: GameSessionStatus.UNSTARTED,
    initiatedLock: false, // if this is true, it is this admin who initiated the joining lock
    breakoutRoomPlan: [],

    // some platforms don't support us creating breakout rooms so we have to rely on them to create them according
    // to our requirements (users per room)
    breakoutRoomCreationMode: BreakoutRoomCreationModes.AUTOMATIC_ASSIGNMENT,
    groupSizeMin: 0,
    groupSizeMax: 0,
  } as GameSessionState,
  reducers: {
    setGameSession: (state, action) => {
      // this will not include the breakout rooms list which we will need to reset
      // when a new game session is created
      state.gameId = action.payload.game_id;
      state.gameSessionId = action.payload.game_session_id;
      state.players = action.payload.players;
      state.status = action.payload.status;
      state.breakoutRoomPlan = action.payload.breakout_room_plan;
    },
    setGroupSize: (state, action) => {
      const { min, max } = action.payload;
      if (min !== undefined) {
        state.groupSizeMin = min;
      }
      if (max !== undefined) {
        state.groupSizeMax = max;
      }
    },
    addPlayer: (state, action) => {
      // only add if not already in the list
      if (!state.players.includes(action.payload)) {
        state.players.push(action.payload);
      }
    },
    removePlayer: (state, action) => {
      state.players = state.players.filter(
        (player) => player !== action.payload
      );
    },
    setInitiatedLock: (state, action) => {
      state.initiatedLock = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setBreakoutRoomPlan: (state, action) => {
      // This should only be ever filled with a value received from the serverside to reduce complexity. If you want
      // to update the breakout room plan, use the serversideInitialiseBreakoutRoomPlan action which will send the request
      // to the server and update the plan in the state once the response is received.
      state.breakoutRoomPlan = action.payload;
    },
    setBreakoutRoomCreationMode: (state, action) => {
      state.breakoutRoomCreationMode = action.payload;
    },
    serversideLockGameSession: (state, action) => {},
    serversideInitialiseBreakoutRoomPlan: (state, action) => {},
    joinPlayerToGame: (state, action) => {},
    joinPlayerToBreakoutRoomPlan: (state, action) => {},
  },
});

export const { actions: gameSessionActions, reducer: gameSessionReducer } =
  gameSessionSlice;
