import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";

import { ReqHeaders } from "../../store/api/auth";
import { ENDPOINTS, GameActionEnum } from "../../store/api/urls";
import { useAuth } from "../../contexts/AuthContext";
import JoinGameSessionButton from "./JoinGameSessionButton";
import { GameSessionStatus, GameSessionType } from "../../hooks/types";

import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { gameSessionActions } from "../../store/slices";
import LoadingMessage from "../LoadingMessage/LoadingMessage";
import PlayerBreakoutRoom from "../PlayerBreakoutRoom/PlayerBreakoutRoom";
import spiralImg from "../../images/spiral-1.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfo,
  faPlay,
  faPuzzlePiece,
  faRotateRight,
  faStop,
} from "@fortawesome/free-solid-svg-icons";

import usePlayerBreakoutRoom from "../../hooks/usePlayerBreakoutRoom";
import CircularButton from "../CircularButton";
import React from "react";

const fetchGameDetails = async (gameId: string, token: string) => {
  const url = ENDPOINTS.games({ gameId, action: GameActionEnum.DETAILS });
  let headers: ReqHeaders = {
    "Content-Type": "application/json",
  };

  const response = await fetch(url, { headers, credentials: "include" }); // You need to replace this with your API endpoint.
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

const PlayAgainComponent = ({
  handleReplay,
  showPlayAgainSpinner,
}: {
  handleReplay: () => void;
  showPlayAgainSpinner: boolean;
}) => {
  const MemoizedFaRotateRight = () => (
    <FontAwesomeIcon icon={faRotateRight} size={"3x"} />
  );
  const MemoFaRotateRightIcon = React.memo(MemoizedFaRotateRight);

  return (
    <>
      {!showPlayAgainSpinner && (
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="auto"
          overlay={
            <Popover id={`replay-game-popover`}>
              <Popover.Body>{"Play again?"}</Popover.Body>
            </Popover>
          }
        >
          <CircularButton
            onClick={handleReplay}
            className="btn btn-danger rounded-circle p-5 shadow-lg btn-lg text-white ms-2 me-2"
            children={<MemoFaRotateRightIcon />}
          />
        </OverlayTrigger>
      )}
      {showPlayAgainSpinner && (
        <div className="spinner-border ms-2" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
    </>
  );
};

const AdminControls = ({
  gameSession,
  gameReady,
  onNewGame,
  gameDetails,
}: {
  gameSession: GameSessionType;
  gameReady: boolean;
  onNewGame: () => void;
  gameDetails: any;
}) => {
  const [showPlayAgainSpinner, setShowPlayAgainSpinner] =
    useState<boolean>(false);

  const dispatch = useDispatch();
  const handleStartGame = () => {
    // First we lock the game session so no one else can join
    // Once the signalling server has locked the game session, it will send a message to the game
    // server to start the game
    dispatch(
      gameSessionActions.serversideLockGameSession(GameSessionStatus.STARTING)
    );
  };

  const handleFinishGameForAll = () => {
    dispatch(
      gameSessionActions.serversideLockGameSession(GameSessionStatus.ENDED)
    );
  };

  const handleReplay = () => {
    setShowPlayAgainSpinner(true);
    // we'll give a little time to show the user the spinner and make it obvious that something is happening
    setTimeout(() => {
      setShowPlayAgainSpinner(false);
      dispatch(
        gameSessionActions.serversideLockGameSession(
          GameSessionStatus.UNSTARTED
        )
      );
    }, 2000);
  };

  let breakoutRoom = `Breakout rooms of: ${gameDetails.group_size_min} - ${gameDetails.group_size_max} people`;
  if (gameDetails.group_size_max === 1) {
    breakoutRoom = "A group game - but people participate as individuals";
  }

  const MemoizedFaPuzzlePiece = () => <FontAwesomeIcon icon={faPuzzlePiece} />;
  const MemoFaPuzzlePieceIcon = React.memo(MemoizedFaPuzzlePiece);

  const MemoizedFaPlay = () => <FontAwesomeIcon icon={faPlay} size={"3x"} />;
  const MemoFaPlayIcon = React.memo(MemoizedFaPlay);

  const MemoizedFaInfo = () => <FontAwesomeIcon icon={faInfo} />;
  const MemoFaInfoIcon = React.memo(MemoizedFaInfo);

  // faStop
  const MemoizedFaStop = () => <FontAwesomeIcon icon={faStop} size={"3x"} />;
  const MemoFaStopIcon = React.memo(MemoizedFaStop);

  return (
    <div className="mt-3 row align-items-center">
      {[GameSessionStatus.ENDED, GameSessionStatus.UNSTARTED].indexOf(
        gameSession.status
      ) > -1 && (
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="auto"
          overlay={
            <Popover id={`change-game-popover`} className="">
              <Popover.Body>Change Game</Popover.Body>
            </Popover>
          }
        >
          <CircularButton
            className="btn btn-info shadow-lg btn-lg text-white"
            onClick={onNewGame}
            children={<MemoFaPuzzlePieceIcon />}
          />
        </OverlayTrigger>
      )}

      {[GameSessionStatus.ENDED].indexOf(gameSession.status) > -1 && (
        <PlayAgainComponent
          handleReplay={handleReplay}
          showPlayAgainSpinner={showPlayAgainSpinner}
        />
      )}

      {gameSession.status === GameSessionStatus.UNSTARTED && (
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="auto"
          overlay={
            <Popover id={`start-game-popover`}>
              <Popover.Body>
                {gameReady ? "Start Game" : "Your team isn't ready yet."}
              </Popover.Body>
            </Popover>
          }
        >
          <CircularButton
            className="btn btn-danger p-5 shadow-lg btn-lg text-white ms-2 me-2"
            onClick={handleStartGame}
            disabled={!gameReady}
            children={<MemoFaPlayIcon />}
          />
        </OverlayTrigger>
      )}

      {[GameSessionStatus.UNSTARTED, GameSessionStatus.ENDED].indexOf(
        gameSession.status
      ) > -1 && (
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="auto"
          overlay={
            <Popover id={`change-game-popover`} className="">
              <Popover.Header>{gameDetails.name}</Popover.Header>
              <Popover.Body>
                <p>{gameDetails.description}</p>
                <p>Player limit: Unlimited</p>
                <p>{breakoutRoom}</p>
                <p>
                  Takes around: {gameDetails.time_requirement} minute
                  {gameDetails.time_requirement > 1 ? "s" : ""}
                </p>
              </Popover.Body>
            </Popover>
          }
        >
          <CircularButton
            className="btn btn-info shadow-lg btn-lg text-white"
            children={<MemoFaInfoIcon />}
          />
        </OverlayTrigger>
      )}

      {/* Put a spinner here if gameSession.status !== GameSessionStatus.STARTING */}
      {gameSession.status === GameSessionStatus.STARTING ? (
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Game Starting...</span>
        </div>
      ) : null}

      {gameSession.status === GameSessionStatus.STARTED ? (
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="auto"
          overlay={
            <Popover id={`finish-game-popover`}>
              <Popover.Body>Finish game for everyone!</Popover.Body>
            </Popover>
          }
        >
          <CircularButton
            className="btn btn-danger p-5 shadow-lg btn-lg text-white ms-2 me-2"
            onClick={handleFinishGameForAll}
            children={<MemoFaStopIcon />}
          />
        </OverlayTrigger>
      ) : null}
    </div>
  );
};

type GameSessionHeaderProps = {
  gameId?: string;
  gameSession: GameSessionType;
  onNewGame: () => void;
};

const GameSessionHeader = ({
  gameId,
  gameSession,
  onNewGame,
}: GameSessionHeaderProps) => {
  const { token } = useAuth();
  const { isAdmin } = useSelector((state: RootState) => state.session);
  const { publicUUID } = useSelector((state: RootState) => state.user);
  const [gameReady, setGameReady] = useState<boolean>(false);
  const [canJoin, setCanJoin] = useState<boolean>(true);
  const breakoutRoomPlan = useSelector(
    (state: RootState) => state.gameSession.breakoutRoomPlan
  );

  const [exampleRoomSize, setMaxRoomSize] = useState<number>(30);

  useEffect(() => {
    if (!gameSession || !publicUUID) return;

    if (gameSession.players.includes(publicUUID)) {
      setCanJoin(false);
    } else {
      setCanJoin(true);
    }
  }, [gameSession, publicUUID]);

  const {
    isLoading,
    isError,
    data: gameDetails,
  } = useQuery(
    ["gameDetails", gameId],
    () => {
      if (gameId && token) return fetchGameDetails(gameId, token);
    },
    { refetchOnWindowFocus: false } // We don't want to refetch when the user switches tabs
  );

  const containerRef = useRef<HTMLDivElement>(null);

  const [minBgHeight, setMinBgHeight] = useState<number | undefined>();
  const roomIndex = usePlayerBreakoutRoom();

  // we always want to show at least 5 placeholder places for players so as the player list increases, increase the maxRoomSize to be at least 5 more than the number of players (with a minimum of 25)
  useEffect(() => {
    console.log({ gameSession });
    if (gameSession.players.length > exampleRoomSize - 5) {
      setMaxRoomSize(gameSession.players.length + 5);
    }
  }, [exampleRoomSize, gameSession, gameSession.players.length]);

  useEffect(() => {
    if (!gameDetails) {
      setGameReady(false);
      return;
    }

    if (gameDetails.group_size_min <= gameSession.players.length) {
      setGameReady(true);
      return;
    }

    setGameReady(false);
  }, [gameDetails, gameSession.players.length]);

  // this will set the height of the background image to be the height of the top of the current div
  // to the bottom of the viewport
  useEffect(() => {
    console.log("handleResize");
    if (!containerRef.current) {
      console.log("handleResize - no container ref");
      return;
    }

    const height = window.innerHeight - containerRef.current.offsetTop;
    setMinBgHeight(height);
  }, [isLoading, containerRef]);

  if (isLoading) {
    return (
      <div
        className="row"
        ref={containerRef}
        style={{
          height: minBgHeight ? `${minBgHeight}px` : "auto",
        }}
      >
        <div className="col-3 text-center text-light position-absolute top-50 start-50 translate-middle bg-info rounded-1 p-3">
          <LoadingMessage message="Loading game details" showSpinner={true} />
        </div>
      </div>
    );
  }

  const BannerMessage = ({
    color,
    message,
  }: {
    color: string;
    message: string;
  }) => {
    return (
      <div
        className="row"
        ref={containerRef}
        style={{
          height: minBgHeight ? `${minBgHeight}px` : "auto",
        }}
      >
        <div
          className={`col-3 text-center text-light position-absolute top-50 start-50 translate-middle bg-${color} bg-gradient p-3`}
        >
          <p className="mb-0">{message}</p>
        </div>
      </div>
    );
  };

  if (isError) {
    return (
      <BannerMessage color="warning" message="Error fetching game details" />
    );
  }

  if (!gameDetails) {
    return (
      <BannerMessage color="warning" message="Error loading game details" />
    );
  }

  if (!isAdmin && roomIndex === null) {
    return (
      <BannerMessage
        color="warning"
        message="Please select your breakout room"
      />
    );
  }

  if (isAdmin && breakoutRoomPlan.length === 0) {
    return (
      <BannerMessage color="warning" message="Please set up breakout rooms" />
    );
  }

  return (
    <>
      <div
        className="row"
        ref={containerRef}
        style={{
          height: minBgHeight ? `${minBgHeight}px` : "auto",
        }}
      >
        <div
          className="col-3"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={spiralImg}
            className={`img-fluid ${styles.spinLeft}`}
            alt="spiral"
            style={{ maxWidth: "50%" }}
          />
        </div>
        <div
          className="col-6"
          style={{
            // put the content in the center of the card
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {roomIndex !== null && (
            <>
              {!isAdmin && (
                <>
                  <JoinGameSessionButton gameReady={gameReady} size={"lg"} />

                  {gameReady &&
                    gameSession.status === GameSessionStatus.UNSTARTED &&
                    !canJoin && (
                      <div className="text-center text-light bg-info rounded-1 p-3 m-5">
                        <LoadingMessage
                          message="Waiting for your host to start the game"
                          showSpinner={false}
                        />
                      </div>
                    )}

                  {!gameReady &&
                    gameSession.status === GameSessionStatus.UNSTARTED && (
                      <div className="text-center text-light bg-info rounded-1 p-3 m-5">
                        <LoadingMessage
                          message="Waiting for everyone to join the game"
                          showSpinner={false}
                        />
                      </div>
                    )}
                </>
              )}
            </>
          )}

          <PlayerBreakoutRoom />

          {isAdmin && (
            <AdminControls
              gameSession={gameSession}
              gameReady={gameReady}
              onNewGame={onNewGame}
              gameDetails={gameDetails}
            />
          )}
        </div>

        <div
          className="col-3"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            transform: "rotateY(180deg)",
          }}
        >
          <img
            src={spiralImg}
            className={`img-fluid ${styles.spinLeft}`}
            alt="spiral"
            style={{ maxWidth: "50%" }}
          />
        </div>
      </div>
    </>
  );
};

export default GameSessionHeader;
