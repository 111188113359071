import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleHalfStroke,
  faSun,
  faMoon,
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import styles from "./styles.module.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { Theme } from "../../types";

library.add(faCircleHalfStroke, faSun, faMoon);

const ColourModeDropdown: React.FC = () => {
  const { theme, setTheme } = useTheme();

  const handleThemeChange = (theme: Theme) => {
    setTheme(theme);
  };

  //   get the appropriate icon for the current theme
  const getIcon = () => {
    switch (theme) {
      case "light":
        return faSun;
      case "dark":
        return faMoon;
      case "auto":
        return faCircleHalfStroke;
    }
  };

  return (
    // align to the end
    <Dropdown align="end">
      <Dropdown.Toggle variant="transparent">
        <FontAwesomeIcon icon={getIcon()} />
        <span className="sr-only ms-2">Toggle theme</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => {
            handleThemeChange("light");
          }}
        >
          <FontAwesomeIcon icon={faSun} /> Light
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            handleThemeChange("dark");
          }}
        >
          <FontAwesomeIcon icon={faMoon} /> Dark
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            handleThemeChange("auto");
          }}
        >
          <FontAwesomeIcon icon={faCircleHalfStroke} /> Auto
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ColourModeDropdown;
