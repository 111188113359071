// Problem Statement:

// Given a number n, you want to express n as a sum of numbers where every term in the sum is between minNum (inclusive) and maxNum (inclusive). The goal is to use the median number (between minNum and maxNum) as much as possible in the sum. If there are two medians, use the greater one as much as possible. If there are no medians, use the smallest number as much as possible.

// Parameters:

//     n: The number you want to express as a sum.
//     minNum: The smallest number you can use in the sum.
//     maxNum: The largest number you can use in the sum.

// Objective:

// Find a sequence of numbers between minNum and maxNum, such that their sum is equal to n.

// For the given example:

// n=29
// minNum=3
// maxNum=5

// The solution is:
// 4 + 4 + 4 + 4 + 4 + 4 + 5 = 29

interface calculateBreakoutRoomSizeArgs {
  n: number; // Total number of participants to be allocated - including those already in rooms
  minNum: number; // Minimum room size
  maxNum: number; // Maximum room size
  existingRooms?: number[]; // Existing breakout rooms
}

interface BreakoutRoomResult {
  rooms: number[]; // Array containing room sizes
  unallocated: number; // Number of unallocated participants
}

export function calculateBreakoutRoomSize({
  n,
  minNum,
  maxNum,
  existingRooms = [],
}: calculateBreakoutRoomSizeArgs): BreakoutRoomResult {
  // Start with all participants as unallocated
  let unallocated = n;

  // If there are fewer participants than the minimum room size, return existing rooms and unallocated participants
  if (n < minNum) {
    return { rooms: existingRooms, unallocated: n };
  }

  // Calculate the median room size
  let range = maxNum - minNum + 1;
  let median: number;
  if (range % 2 === 0) {
    median = minNum + range / 2 - 1;
  } else {
    median = minNum + Math.floor(range / 2);
  }

  // Calculate the number of full rooms of median size we can have
  let quotient = Math.floor(n / median);
  let remainder = n % median; // Participants left after allocating full rooms of median size

  // Create an initial result filled with rooms of median size
  let result: number[] = Array(quotient).fill(median);

  // Distribute any remaining participants among the rooms
  while (remainder > 0) {
    // If the remainder is larger than or equal to the median, add another median-sized room
    if (remainder >= median) {
      result.push(median);
      remainder -= median;
    }
    // If the remainder can be added to the last room without exceeding the max room size, do so
    else if (remainder + result[result.length - 1] <= maxNum) {
      result[result.length - 1] += remainder;
      remainder = 0;
    }
    // If the remainder is larger than or equal to the min room size, create a room with the remainder size
    else if (remainder >= minNum) {
      result.push(remainder);
      remainder = 0;
    } else {
      break; // If the remainder can't be allocated to a room, exit the loop.
    }
  }

  // Try to distribute the remaining participants into existing rooms up to maxNum
  for (let i = 0; i < result.length && remainder > 0; i++) {
    let spaceLeftInRoom = maxNum - result[i];
    let toAdd = Math.min(spaceLeftInRoom, remainder);
    result[i] += toAdd;
    remainder -= toAdd;
  }

  // If we still have some participants left, and they fit in a valid new room, create that room
  if (remainder >= minNum && remainder <= maxNum) {
    result.push(remainder);
    remainder = 0;
  }

  // Subtract the number of participants we've successfully allocated from the unallocated count
  unallocated -= n - remainder;

  // Return the room sizes and the number of unallocated participants
  return { rooms: result, unallocated };
}

type generateBreakoutRoomArgs = {
  playerIds: string[]; // players to allocate
  minPlayers: number; // minimum room size
  maxPlayers: number; // maximum room size
  existingRooms?: string[][]; // Existing breakout rooms
};

interface BreakoutRoomWithPlayersResult {
  rooms: string[][]; // Array containing array of playerIds in each room
  unallocated: string[]; // Unallocated participants
}

export function generateBreakoutRooms({
  playerIds,
  minPlayers,
  maxPlayers,
  existingRooms = [],
}: generateBreakoutRoomArgs): BreakoutRoomWithPlayersResult {
  // Make a copy of the original playerIds so we don't mutate the original array
  let shuffledPlayers = [...playerIds];

  // Deep copy existingRooms to prevent mutation of the original array
  let copiedExistingRooms = existingRooms.map((room) => [...room]);

  // If there are no players, assume the user wants to reshuffle the existing rooms
  if (shuffledPlayers.length === 0) {
    // put all the players from copiedExistingRooms into shuffledPlayers
    shuffledPlayers = copiedExistingRooms.reduce((acc, room) => {
      return acc.concat(room);
    }, [] as string[]);

    // Clear copiedExistingRooms
    copiedExistingRooms = [];
  }

  // check if any existing rooms are below minPlayers and if so move them to shuffledPlayers and remove the room
  // console.log(
  //   "Look for rooms below minPlayers",
  //   { copiedExistingRooms },
  //   { shuffledPlayers }
  // );
  copiedExistingRooms = copiedExistingRooms.filter((room) => {
    if (room.length < minPlayers) {
      shuffledPlayers = shuffledPlayers.concat(room);
      return false;
    }
    return true;
  });
  // console.log(
  //   "Rooms below minPlayers removed",
  //   { copiedExistingRooms },
  //   { shuffledPlayers }
  // );

  // check if any existing rooms are above maxPlayers and if so move the excess players to shuffledPlayers
  // console.log(
  //   "Look for rooms above maxPlayers",
  //   { copiedExistingRooms },
  //   { shuffledPlayers }
  // );
  copiedExistingRooms = copiedExistingRooms.map((room) => {
    if (room.length > maxPlayers) {
      const excessPlayers = room.splice(maxPlayers);
      shuffledPlayers = shuffledPlayers.concat(excessPlayers);
    }
    return room;
  });
  // console.log(
  //   "Rooms above maxPlayers adjusted",
  //   { copiedExistingRooms },
  //   { shuffledPlayers }
  // );

  let breakoutRoomPlan: string[][] = [];

  // Calculate the size of each breakout room based on the total number of players, constraints, and existing rooms
  const totalParticipants =
    shuffledPlayers.length +
    copiedExistingRooms.reduce((sum, room) => sum + room.length, 0);
  const {
    rooms: expectedBreakoutRoomSizes,
    unallocated: expectedUnallocatedCount,
  } = calculateBreakoutRoomSize({
    n: totalParticipants,
    minNum: minPlayers,
    maxNum: maxPlayers,
    existingRooms: copiedExistingRooms.map((room) => room.length),
  });

  /*
   * First, we need to rebalance the existing rooms (if any) to match the new sizes. This is done by either:
   *   - Removing players from a room and adding them to the unallocated list
   *   - Adding players from the unallocated list to a room
   *
   * Removing players comes first. Otherwise we could end up trying to add players to a room from an empty
   * shuffledPlayers list.
   */

  // Remove players from existing rooms if necessary
  for (let i = 0; i < copiedExistingRooms.length; i++) {
    while (copiedExistingRooms[i].length > expectedBreakoutRoomSizes[i]) {
      const playerToRemove = copiedExistingRooms[i].pop()!;
      shuffledPlayers.push(playerToRemove);
    }
  }

  /*
   * Shuffle the array of players randomly using the Fisher-Yates (Durstenfeld) algorithm
   */
  for (let i = shuffledPlayers.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    // Swap shuffledPlayers[i] with shuffledPlayers[j]
    [shuffledPlayers[i], shuffledPlayers[j]] = [
      shuffledPlayers[j],
      shuffledPlayers[i],
    ];
  }

  // Now add players to rooms that are too small, pulling from the front of the shuffledPlayers list.

  let currentIndex = 0;
  for (let i = 0; i < copiedExistingRooms.length; i++) {
    while (copiedExistingRooms[i].length < expectedBreakoutRoomSizes[i]) {
      if (currentIndex >= shuffledPlayers.length) {
        throw new Error("Not enough players to allocate to existing rooms.");
      }

      const playerToAdd = shuffledPlayers[currentIndex++];
      copiedExistingRooms[i].push(playerToAdd);
    }
  }

  // Add the adjusted existing rooms to the breakoutRooms array.
  breakoutRoomPlan = breakoutRoomPlan.concat(copiedExistingRooms);

  /*
   * Now, we can create any new breakout rooms and add unallocated players to them in the numbers specified by
   * breakoutRoomSizes
   */

  for (
    let i = copiedExistingRooms.length;
    i < expectedBreakoutRoomSizes.length;
    i++
  ) {
    let size = expectedBreakoutRoomSizes[i];

    // If we don't have enough players to fill the room, throw an error
    if (currentIndex + size > shuffledPlayers.length) {
      throw new Error("Not enough players to allocate to new rooms.");
    }

    let room = shuffledPlayers.slice(currentIndex, currentIndex + size);
    breakoutRoomPlan.push(room);
    currentIndex += size;
  }

  // Collect unallocated players
  const unallocatedPlayers = shuffledPlayers.slice(currentIndex);

  // sort each room alphabetically by player name
  breakoutRoomPlan.forEach((room) => {
    room.sort();
  });

  // Return the breakout rooms with distributed players and the list of unallocated players
  return { rooms: breakoutRoomPlan, unallocated: unallocatedPlayers.sort() };
}
