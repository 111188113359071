import { useState } from "react";
import { Breadcrumb } from "react-bootstrap";

export enum NewGameStepsEnum {
  GAME_LENGTH = "Game Length",
  GROUP_SIZE = "Breakout Room Size",
  GAME_CHOICES = "Select Game",
  CREATING_GAME_SESSION = "Creating Game Session",
}

type GameSessionStepsProps = {
  currentStep: NewGameStepsEnum;
  onStepChange: (step: NewGameStepsEnum) => void;
};

const GameSessionSteps = ({
  currentStep,
  onStepChange,
}: GameSessionStepsProps) => {
  //   const [selectedStep, setSelectedStep] = useState(currentStep);

  const handleStepChange = (step: NewGameStepsEnum) => {
    // setSelectedStep(step);
    onStepChange(step);
  };

  const steps = Object.values(NewGameStepsEnum);

  return (
    <Breadcrumb>
      <Breadcrumb.Item
        active={false}
        onClick={() => {
          handleStepChange(NewGameStepsEnum.GAME_LENGTH);
        }}
      >
        New Game Session
      </Breadcrumb.Item>
      {steps.map((step, index) => {
        // if the step index is greater than the current step index then
        // break out of the loop
        if (index > steps.indexOf(currentStep)) {
          return null;
        }
        return (
          <Breadcrumb.Item
            key={step}
            active={step === currentStep}
            onClick={() => {
              // only allow going back
              if (index < steps.indexOf(currentStep)) {
                handleStepChange(step);
              }
            }}
          >
            {step}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export default GameSessionSteps;
