import React, { createContext, useContext, useEffect, useState } from "react";

import { Theme } from "../types";

type ThemeContextType = {
  theme: Theme;
  setTheme: React.Dispatch<React.SetStateAction<Theme>>;
  isDarkMode: boolean;
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>("auto");
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);

  // Pull the initial theme from localStorage if it exists
  useEffect(() => {
    const localTheme = localStorage.getItem("theme");
    if (localTheme) {
      setTheme(localTheme as Theme);
    }
  }, []);

  // And also where you'd add the useEffect to handle changes and save to localStorage
  useEffect(() => {
    // Determine the actual theme value if 'auto' is selected
    let actualTheme = theme;
    if (theme === "auto") {
      actualTheme = window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light";
    }

    // Set the attribute on the <html> element
    document.documentElement.setAttribute("data-bs-theme", actualTheme);

    // Set the state in the component
    setIsDarkMode(actualTheme === "dark");

    // Save to localStorage if you want persistence across sessions
    localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme, isDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
