export function postMessageToParent(message: any, targetOrigin: string) {
  window.parent.postMessage(message, targetOrigin);
}

export const getVerticalScrollbarWidth = () => {
  const beforeScrollbarHidden = document.body.clientHeight;
  const overflowState = document.body?.style.overflow;

  document.body.style.overflow = "hidden"; // hide the scrollbar
  const afterScrollbarHidden = document.body.clientHeight;
  document.body.style.overflow = overflowState; // restore the overflow state

  return beforeScrollbarHidden - afterScrollbarHidden;
};
