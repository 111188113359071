export interface Game {
  id: string;
  name: string;
  description: string;
  platforms_supported: [string];
  peripherals_required: [string];
  integrations_available: [string];
  accessibility_requirements: [string];
  group_size_min: number;
  group_size_max: number;
  time_requirement: number;
  image_url: string;
}

export interface Session {
  id: string;
  url: string;
}

export type Theme = "light" | "dark" | "auto";

// This enum outlines the types of messages that we will send to the parent window e.g. session id allocated
export const PostMessageTypes = {
  IFRAME_RESIZE: "iframe-resize",
  IS_SESSION_CONNECTED: "is-session-connected",
  IS_GAME_SELECTED: "is-game-selected",
  SESSION_ID_ALLOCATED: "session-id-allocated",
  BREAKOUT_ROOM_SIZE_REQUIREMENTS: "breakout-room-size-requirements", // minimum and maximum group size for the breakout rooms
  BREAKOUT_ROOMS_OPENED: "breakout-rooms-opened", // breakout rooms have been opened - but we're awaiting users to be assigned
  BREAKOUT_ROOMS_ASSIGNED: "breakout-rooms-assigned", // users have been assigned to breakout rooms
  BREAKOUT_ROOM_STATUS_CHANGED: "breakout-room-status-changed", // relates to game status
  COLLAB_USER_ID_ASSIGNED: "collab-user-id-assigned",
};

// This enum outlines the events that we will listen for from the parent window e.g. update user metadata
export const PostMessageEvents = {
  UPDATE_USER_PUBLIC_METADATA: "update-user-public-metadata",
  UPDATE_IS_ADMIN: "update-is-admin",
  UPDATE_BREAKOUT_ROOM_CREATION_MODE: "update-breakout-room-creation-mode",
  OPEN_BREAKOUT_ROOMS: "open-breakout-rooms",
  JOIN_PLAYER_TO_GAME: "join-player-to-game",
  JOIN_PLAYER_TO_BREAKOUT_ROOM: "join-player-to-breakout-room",
  VIEWPORT_HEIGHT: "viewport-height",
};
