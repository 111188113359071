// api.ts

import { ReqHeaders } from "./auth";
import { ENDPOINTS } from "./urls";

export const updatePublicMetadataOnServer = async ({
  uuid,
  key,
  value,
  sessionId,
}: {
  uuid: string;
  key: string;
  value: string;
  sessionId: string;
}) => {
  let headers: ReqHeaders = {
    "Content-Type": "application/json",
  };

  // payload key and value must be of string type
  const payload: { [key: string]: string } = {
    session_id: sessionId,
  };

  payload[key] = value;

  const response = await fetch(`${ENDPOINTS.people({ uuid })}`, {
    method: "PUT",
    headers,
    credentials: "include",
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw new Error("Failed to update name on server");
  }

  return response.json();
};

export const updateIsAdminOnServer = async ({
  uuid,
  isAdmin,
  sessionId,
}: {
  uuid: string;
  isAdmin: boolean;
  sessionId: string;
}) => {
  let headers: ReqHeaders = {
    "Content-Type": "application/json",
  };

  const payload = {
    is_admin: isAdmin,
    uuid,
  };

  const response = await fetch(
    `${ENDPOINTS.sessions({ sessionId, action: "admins" })}`,
    {
      method: "PUT",
      headers,
      credentials: "include",
      body: JSON.stringify(payload),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to update name on server");
  }

  return response.json();
};
