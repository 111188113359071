import { all, fork } from "redux-saga/effects";
import gameSessionSaga from "./gameSessionSaga";
import sessionSaga from "./sessionSaga";
import userSaga from "./userSaga";
import breakoutRoomSaga from "./breakoutRoomSaga";

export default function* rootSaga() {
  yield all([
    fork(gameSessionSaga),
    fork(sessionSaga),
    fork(userSaga),
    fork(breakoutRoomSaga),
    // ... other sagas
  ]);
}
