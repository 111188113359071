import { Session } from "../../types";

import { ENDPOINTS } from "./urls";

const session: Session = {
  id: "1",
  url: "https://via.placeholder.com/500x500",
};
// // Simulating API fetch
// const createSession = (): Promise<Session> =>
//   new Promise((resolve) => setTimeout(() => resolve(session), 5000));

// The fetch function
async function createSession({ token }: { token: string | null }) {
  console.log({ token });
  if (!token) {
    throw new Error("Token is missing.");
  }

  const response = await fetch(ENDPOINTS.sessions(), {
    method: "POST",
    credentials: "include",
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
}

export { createSession };
