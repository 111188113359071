import React, { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import VisitorList from "../VisitorList/VisitorList";
import {
  faCog,
  faCircleUser,
  faAddressBook,
} from "@fortawesome/free-solid-svg-icons";

import { Popover, OverlayTrigger, Button } from "react-bootstrap";
import { appName, localStorageEnum } from "../../utils/constants";
import useLocalStorage from "../../hooks/useLocalStorage";
import NameModal from "../NameModal/NameModal";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { userActions } from "../../store/slices/userSlice";
import styles from "./SessionHeader.module.scss";

type SessionHeaderProps = {};

const SessionHeader: React.FC<SessionHeaderProps> = () => {
  const visitors = useSelector((state: RootState) => state.visitors);
  const { isAdmin } = useSelector((state: RootState) => state.session);

  const [username, setUsername] = useLocalStorage<string | null>(
    localStorageEnum.USERNAME,
    null
  );

  const [showUsernameModal, setShowUsernameModal] = useState<boolean>(
    !username
  );

  const dispatch = useDispatch();
  const visitorCount = useMemo(() => {
    return Object.keys(visitors).length;
  }, [visitors]);

  useEffect(() => {
    dispatch(userActions.setName(username));
  }, [username]);

  const popover = (
    <Popover style={{ maxWidth: "80vw" }}>
      <Popover.Header as="h3">People here</Popover.Header>
      <Popover.Body>
        <VisitorList />
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="row align-items-center g-0 ms-3 me-3">
      <div
        className="col text-start"
        // Show the player list if the user hovers over the player count
      >
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="auto"
          overlay={popover}
          defaultShow={false}
        >
          <span
            className="fa-layers fa-fw"
            style={{ cursor: "pointer" }}
            title="Click to see all the people that are here"
          >
            <FontAwesomeIcon icon={faAddressBook} />
            <span className={`fa-layers-counter ${styles.visitorCount}`}>
              {visitorCount}
            </span>
          </span>
        </OverlayTrigger>
      </div>

      <div className="col text-center">
        {/* <h1 className="text-center">
          {isAdmin ? (
            <small>
              <FontAwesomeIcon icon={faCog} />{" "}
            </small>
          ) : null}
          {appName} Session ...
        </h1> */}
      </div>
      <div className="col text-end align-items-center">
        <Button
          variant="link"
          className="link-underline-opacity-0 link-secondary"
          onClick={() => {
            setShowUsernameModal(true);
          }}
        >
          {username}
        </Button>

        <FontAwesomeIcon icon={faCircleUser} />
      </div>

      {/* Use the NameModal component */}
      <NameModal
        show={showUsernameModal}
        onClose={() => {
          setShowUsernameModal(false);
          const storedUsername = localStorage.getItem(
            localStorageEnum.USERNAME
          );
          if (storedUsername && storedUsername !== username) {
            setUsername(storedUsername);
          }
        }}
      />
    </div>
  );
};

export default SessionHeader;
