// slices/sessionSlice.ts
import { createSlice } from "@reduxjs/toolkit";

export interface SessionState {
  isAdmin: boolean;
  sessionId?: string;
}

const sessionSlice = createSlice({
  name: "session",
  initialState: {
    isAdmin: false,
  } as SessionState,
  reducers: {
    setAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
    setSessionId: (state, action) => {
      state.sessionId = action.payload;
    },
  },
});

export const { actions: sessionActions, reducer: sessionReducer } =
  sessionSlice;
