import React, { useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";

interface CountdownProgressBarProps {
  duration?: number; // in milliseconds, default to 3000ms (3 seconds)
  onComplete: () => void;
  message?: string;
}

const NavigationCountdownProgressBar: React.FC<CountdownProgressBarProps> = ({
  duration = 3000,
  onComplete,
  message = "",
}) => {
  const [progress, setProgress] = useState(100);

  const updatesPerSecond = 60; // 60 FPS

  useEffect(() => {
    const startTime = Date.now();

    const interval = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const currentProgress = 100 - (elapsedTime / duration) * 100;

      setProgress(Math.max(currentProgress, 0));

      if (currentProgress <= 0) {
        clearInterval(interval);
        onComplete();
      }
    }, 1000 / updatesPerSecond);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [duration, onComplete]);

  return (
    <ProgressBar
      variant="info"
      now={progress}
      label={`${message} ${Math.round(progress)}%`}
    />
  );
};

export default NavigationCountdownProgressBar;
