import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceLaughBeam } from "@fortawesome/free-solid-svg-icons";

export const BreakoutRoomSlot = ({
  title,
  isWarning,
}: {
  title: string;
  isWarning?: boolean;
  isError?: boolean;
}) => {
  let classIndicator = "col";
  if (isWarning) {
    classIndicator += " text-warning";
  }

  return (
    <div className={`${classIndicator} rounded-2 text-center`}>
      {/* <span className="">
        <FontAwesomeIcon icon={faFaceLaughBeam} className="text-success" />
      </span> */}
      <p className="bg-light p-3 lead text-break m-0">{title}</p>
    </div>
  );
};

const FilledSlots = ({ players }: { players: string[] }) => {
  const visitors = useSelector((state: RootState) => state.visitors);
  const filledSlotsArray = Array(players.length).fill(null);
  // show an empty slot div for each empty slot
  for (let i = 0; i < players.length; i++) {
    const visitor = visitors[players[i]];

    filledSlotsArray.push(
      <BreakoutRoomSlot
        key={players[i]}
        title={`${(visitor && (visitor.name || visitor.sid)) || players[i]}`}
      />
    );
  }
  return <>{filledSlotsArray}</>;
};

interface BreakoutRoomProps {
  roomName: string;
  players: string[];
}

function BreakoutRoom({ roomName, players }: BreakoutRoomProps) {
  return (
    <div className="row w-100">
      <div className="col bg-none bg-gradient text-dark rounded shadow-lg p-5">
        <h5 className="text-center display-6">{roomName}</h5>
        <div className="row row-cols-2 g-2">
          <FilledSlots players={players} />
        </div>
      </div>
    </div>
  );
}

export default BreakoutRoom;
