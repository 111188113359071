import { takeEvery, all, select } from "redux-saga/effects";
import { visitorsActions } from "../slices/visitorsSlice";
import { put } from "redux-saga/effects";
import { sessionActions } from "../slices/sessionSlice";
import { RootState } from "../index";

function* updateSessionAdminStatus() {
  // get publicUUID from the store - it's in the user slice
  const user: RootState["user"] = yield select(
    (state: RootState) => state.user
  );

  const { publicUUID } = user;

  const visitors: RootState["visitors"] = yield select(
    (state: RootState) => state.visitors
  );

  // Compute sessionAdminsSIDs similar to how you do with useMemo
  const sessionAdminsSIDs = Object.keys(visitors).filter(
    (sid) => visitors[sid].isAdmin
  );

  const isAdmin = Boolean(publicUUID && sessionAdminsSIDs.includes(publicUUID));

  yield put(sessionActions.setAdmin(isAdmin));
}

export default function* sessionSaga() {
  yield all([
    takeEvery(
      visitorsActions.setVisitorAdminStatus.type,
      updateSessionAdminStatus
    ),
    // ... other watchers for sessionSaga
    // e.g. takeEvery(gameActions.someOtherAction.type, anotherGameSaga)
  ]);
}
