import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { checkTokenValidity, fetchVisitorAuthToken } from "../store/api/auth";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { userActions } from "../store/slices";

export const TokenEnum = {
  ACCESS_TOKEN: "access-token",
  TOKEN_EXPIRES: "access-token-expires",
  PUBLIC_UUID: "public-uuid",
};

const AuthTokenClasses = {
  VISITOR: "visitor",
  MEMBER: "member",
};

interface AuthContextType {
  isVisitor: boolean;
  isMember: boolean;
  isLoading: boolean;
  token: string | null;
  publicUUID: string | null;
  user: any | null;
  authenticateVisitor: () => Promise<void>;
  loginUser: (credentials: any) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}): React.JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [isVisitor, setIsVisitor] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [publicUUID, setPublicUUID] = useState<string | null>(null);
  const [user, setUser] = useState<any | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const storedToken = localStorage.getItem(TokenEnum.ACCESS_TOKEN);
    const storedPublicUUID = localStorage.getItem(TokenEnum.PUBLIC_UUID);
    if (storedToken) {
      setToken(storedToken);
      dispatch(userActions.setToken(storedToken));
      // We'll defer the setting of isVisitor until after the useQuery result
    }
    if (storedPublicUUID) {
      setPublicUUID(storedPublicUUID);
      dispatch(userActions.setPublicUUID(storedPublicUUID));
    }
  }, [dispatch]); // An empty dependency array ensures this effect runs once on mount.

  const { data: tokenData, error } = useQuery(
    ["tokenValidity", token],
    () => checkTokenValidity(token),
    {
      enabled: !!token, // Only run if there's a token.
      retry: false, // Do not retry on failure.
      refetchOnWindowFocus: false, // To not refetch on window focus.
    }
  );

  const authenticateVisitor = useCallback(async () => {
    if (isLoading) return; // Don't run if already in progress

    setIsLoading(true);
    try {
      const { accessToken, expires, publicUUID } = await fetchVisitorAuthToken({
        oldToken: token,
      });
      setIsVisitor(true);
      setToken(accessToken);
      dispatch(userActions.setToken(accessToken));
      setPublicUUID(publicUUID);
      dispatch(userActions.setPublicUUID(publicUUID));
      localStorage.setItem(TokenEnum.ACCESS_TOKEN, accessToken);
      localStorage.setItem(TokenEnum.TOKEN_EXPIRES, expires);
      localStorage.setItem(TokenEnum.PUBLIC_UUID, publicUUID);
    } catch (error) {
      // TODO: Handle the error. You could do several things here:
      // 1. Log the error.
      console.error("Error fetching visitor auth token:", error);

      // 2. Maybe set some state to inform the user about the error.
      // setSomeState("Failed to authenticate as a visitor.");

      // 3. Depending on your requirements, you could also re-throw the error
      // if there's another part of your app that might want to handle it.
      // throw error;
    }
    setIsLoading(false);
  }, [token, dispatch, isLoading]);

  useEffect(() => {
    if (tokenData) {
      if (tokenData.is_valid) {
        setIsVisitor(tokenData.token_class === AuthTokenClasses.VISITOR);
        setIsMember(tokenData.token_class === AuthTokenClasses.MEMBER);
      }
    }

    if (error) {
      console.error("Error checking token:", error);
      // Handle token validation errors
      // 1. Remove the token from local storage
      localStorage.removeItem(TokenEnum.ACCESS_TOKEN);
      localStorage.removeItem(TokenEnum.TOKEN_EXPIRES);
      localStorage.removeItem(TokenEnum.PUBLIC_UUID);

      // 2. Set the token state to null
      setToken(null);

      // 3. Set the user state to null
      setUser(null);

      // 4. Set the publicUUID state to null
      setPublicUUID(null);

      // 5. Set the redux publicUUID state to null
      dispatch(userActions.setPublicUUID(publicUUID));

      // 6. Set the redux token state to null
      dispatch(userActions.setToken(null));

      // Run authenticateVisitor to get a new visitor token
      authenticateVisitor();
    }

    setIsLoading(false);
  }, [tokenData, error, publicUUID, dispatch, authenticateVisitor]);

  const loginUser = async (credentials: any) => {
    // Handle the login with username and password
    // Mocking login. Replace with your API call.
    if (
      credentials.username === "admin" &&
      credentials.password === "password"
    ) {
      const token = TokenEnum.ACCESS_TOKEN;
      const expires = "1243";
      const publicUUID = "213";
      const user = { id: 1, name: "Admin User" };
      setIsMember(true);
      setToken(token);
      setUser(user);
      localStorage.setItem(TokenEnum.ACCESS_TOKEN, token);
      localStorage.setItem(TokenEnum.TOKEN_EXPIRES, expires);
      localStorage.setItem(TokenEnum.PUBLIC_UUID, publicUUID);
    }
  };

  const logout = () => {
    setIsVisitor(false);
    setIsMember(false);
    setToken(null);
    setUser(null);
    localStorage.removeItem(TokenEnum.ACCESS_TOKEN);
    localStorage.removeItem(TokenEnum.TOKEN_EXPIRES);
    localStorage.removeItem(TokenEnum.PUBLIC_UUID);
  };

  return (
    <AuthContext.Provider
      value={{
        isVisitor,
        isMember,
        token,
        publicUUID,
        user,
        authenticateVisitor,
        loginUser,
        logout,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
